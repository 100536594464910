import React, { useState } from 'react';

const Footer = () => {
  const [isPrivacyOpen, setIsPrivacyOpen] = useState(false);
  const [isTermsOpen, setIsTermsOpen] = useState(false);

  const openPrivacyModal = () => setIsPrivacyOpen(true);
  const closePrivacyModal = () => setIsPrivacyOpen(false);
  const openTermsModal = () => setIsTermsOpen(true);
  const closeTermsModal = () => setIsTermsOpen(false);

  const handleOutsideClick = (e, closeModal) => {
    if (e.target === e.currentTarget) {
      closeModal();
    }
  };

  return (
    <>
      <footer className="bg-[#2b2b2b] text-white py-6 border-t border-red-600">
        <div className="container mx-auto flex flex-col md:flex-row justify-between items-center px-6 space-y-4 md:space-y-0">
          <div className="w-full md:w-1/3 text-center md:text-left">
            <p className="text-lg">Contact us at: <a href="mailto:info@snappcode.eu" className="text-blue-400 hover:underline">info@snappcode.eu</a></p>
          </div>
          <div className="w-full md:w-1/3 text-center">
            <p className="text-lg">© 2024 SnappCode. All rights reserved.
            </p>
          </div>
          <div className="w-full md:w-1/3 text-center md:text-right">
            <p className="text-lg">
              <button onClick={openPrivacyModal} className="text-blue-400 hover:underline">Privacy Policy</button> |{' '}
              <button onClick={openTermsModal} className="text-blue-400 hover:underline">Terms of Service</button>
            </p>
          </div>
        </div>
      </footer>

      {isPrivacyOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center" onClick={(e) => handleOutsideClick(e, closePrivacyModal)}>
          <div className="bg-white p-6 rounded-lg max-w-3xl mx-4 max-h-[80vh] overflow-y-auto">
            <h2 className="text-2xl font-bold mb-4 text-black">Privacy Policy</h2>
            <p className="mb-4 text-black">
              Effective Date: October 9, 2024
            </p>
            <p className="mb-4 text-black">
              At SnappCode, we are committed to protecting the privacy and security of your personal information. This Privacy Policy explains how we collect, use, and protect the information you provide to us when accessing or using our services at https://snappcode.eu.
            </p>
            <p className="mb-4 text-black">
              <strong>1. Information We Collect</strong><br />
              <strong>Personal Information:</strong> When you sign up for our services or contact us, we may collect personal information such as your name, email address, and billing information.<br />
              <strong>Usage Data:</strong> We collect data about how you use our services, including interactions, preferences, and generated code. This helps us improve our services and provide a better user experience.
            </p>
            <p className="mb-4 text-black">
              <strong>2. How We Use Your Information</strong><br />
              <strong>Service Provision:</strong> We use the information to provide, maintain, and improve our services, including personalized support and content generation.<br />
              <strong>Communication:</strong> We may use your email to send service-related communications, updates, and information relevant to your use of SnappCode.<br />
              <strong>Billing:</strong> Personal information is used for processing payments and managing subscriptions.
            </p>
            <p className="mb-4 text-black">
              <strong>3. Data Sharing and Disclosure</strong><br />
              <strong>Third-Party Services:</strong> We do not sell or lease your information to third parties. We may share your information with trusted third-party service providers who assist in providing our services, such as payment processors. These third parties are obligated to protect your information and only use it for specific purposes.<br />
              <strong>Legal Requirements:</strong> We may disclose your information if required by law or to comply with a legal process, such as a court order or subpoena.
            </p>
            <p className="mb-4 text-black">
              <strong>4. Security Measures</strong><br />
              We employ industry-standard security measures to protect your personal information from unauthorized access, alteration, or disclosure. Despite our best efforts, no method of data transmission or storage is 100% secure, and we cannot guarantee complete security.
            </p>
            <p className="mb-4 text-black">
              <strong>5. Your Rights</strong><br />
              <strong>Access and Update:</strong> You have the right to access the personal information we hold about you and request corrections if needed.<br />
              <strong>Deletion:</strong> You may request the deletion of your personal data, and we will comply where required by applicable law.
            </p>
            <p className="mb-4 text-black">
              <strong>6. Cookies</strong><br />
              We use cookies and similar technologies to enhance user experience, track usage, and improve our services. You can manage your cookie preferences through your browser settings.
            </p>
            <p className="mb-4 text-black">
              <strong>7. Changes to this Privacy Policy</strong><br />
              We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. Any updates will be posted on our website, and we encourage you to review this policy periodically.
            </p>
            <p className="text-black">
              <strong>8. Contact Information</strong><br />
              If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at <a href="mailto:info@snappcode.eu" className="text-blue-400 hover:underline">info@snappcode.eu</a>.
            </p>
            <button onClick={closePrivacyModal} className="mt-6 px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700">Close</button>
          </div>
        </div>
      )}

      {isTermsOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center" onClick={(e) => handleOutsideClick(e, closeTermsModal)}>
          <div className="bg-white p-6 rounded-lg max-w-3xl mx-4 max-h-[80vh] overflow-y-auto">
            <h2 className="text-2xl font-bold mb-4 text-black">Terms and Conditions</h2>
            <p className="mb-4 text-black">
              Effective Date: October 9, 2024
            </p>
            <p className="mb-4 text-black">
              Welcome to SnappCode, available at https://snappcode.eu. By accessing or using our services, you agree to comply with and be bound by the following Terms and Conditions.
            </p>
            <p className="mb-4 text-black">
              <strong>1. Definitions</strong><br />
              "SnappCode": Refers to our software application and services, including the website, code generation, and boilerplate provision services.<br />
              "User": Refers to any individual or entity accessing or using SnappCode services.<br />
              "Services": Refers to all services provided by SnappCode, including code generation, license provision, and any associated features.<br />
              "License": Refers to the one-year, non-refundable subscription to access SnappCode's code generation capabilities.
            </p>
            <p className="mb-4 text-black">
              <strong>2. Acceptance of Terms</strong><br />
              By using SnappCode, you acknowledge that you have read, understood, and agree to these Terms. If you do not agree with these terms, you must not use our services.
            </p>
            <p className="mb-4 text-black">
              <strong>3. Usage Rights and Restrictions</strong><br />
              Users are granted a one-year non-exclusive license to use SnappCode for generating code and boilerplates. The license does not provide ownership of SnappCode or its intellectual property. Users are prohibited from reverse-engineering, sharing credentials, or using SnappCode to create competing products.
            </p>
            <p className="mb-4 text-black">
              <strong>4. Fees and Payment Terms</strong><br />
              All fees for SnappCode licenses are non-refundable. Payments are due in full upon purchase. No refunds will be provided under any circumstances.
            </p>
            <p className="mb-4 text-black">
              <strong>5. Ownership and Intellectual Property</strong><br />
              SnappCode retains all ownership rights, titles, and interests in and to the services, including software, code, and documentation. Users are not granted rights to use our trademarks, logos, or other proprietary content without prior written consent.
            </p>
            <p className="mb-4 text-black">
              <strong>6. Termination of Accounts</strong><br />
              SnappCode reserves the right to terminate or suspend user accounts for violations of these Terms. No refunds will be provided upon termination.
            </p>
            <p className="mb-4 text-black">
              <strong>7. Data Confidentiality and Security</strong><br />
              We take the confidentiality and security of user data seriously. Personal information collected will be handled according to our Privacy Policy. We implement industry-standard security practices but cannot guarantee complete protection against unauthorized access.
            </p>
            <p className="mb-4 text-black">
              <strong>8. Governing Law and Jurisdiction</strong><br />
              These Terms will be governed by and construed in accordance with the laws of Finland. Any disputes arising will be subject to the exclusive jurisdiction of the courts in Finland.
            </p>
            <p className="mb-4 text-black">
              <strong>9. Limitation of Liability</strong><br />
              SnappCode will not be liable for any indirect, incidental, or consequential damages resulting from the use or inability to use our services. Our liability is limited to the amount of subscription fees paid during the previous year.
            </p>
            <p className="mb-4 text-black">
              <strong>10. Warranties and Disclaimers</strong><br />
              SnappCode is provided "as-is" without warranties of any kind. We do not guarantee the accuracy, reliability, or completeness of the code generated. Users are responsible for verifying the suitability of generated code for their projects.
            </p>
            <p className="text-black">
              <strong>11. Contact Information</strong><br />
              For questions regarding these Terms, please contact us at <a href="mailto:info@snappcode.eu" className="text-blue-400 hover:underline">info@snappcode.eu</a>.
            </p>
            <button onClick={closeTermsModal} className="mt-6 px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700">Close</button>
          </div>
        </div>
      )}
    </>
  );
};

export default Footer;