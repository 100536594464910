import React, { useState } from 'react';
import axiosInstance from '../../axios';

function AdminAddProduct({ initialAccountId = '' }) {
    const [newProduct, setNewProduct] = useState({
        role: '',
        name: '',
        price: '',
        currency: '',
        description: '',
        features: '',
        popular: false,
    });
    const [error, setError] = useState('');
    const [accounts] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewProduct({ ...newProduct, [name]: value });
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setNewProduct({ ...newProduct, [name]: checked });
    };

    const handleAddProduct = async () => {
        try {
            let payload = { ...newProduct, features: newProduct.features.split(',') };

            const response = await axiosInstance.post('admin/createProduct', payload);
            setNewProduct({ role: '', name: '', price: '', currency: '', description: '', features: '', popular: false });
            setError('');
            setSuccessMessage('Tuote lisätty onnistuneesti');
            console.log(response);
        } catch (error) {
            console.log('Error adding product:', error);
            if (error.response && error.response.data && error.response.data.error) {
                setError(error.response.data.error.message);
            } else {
                setError('Virhe lisättäessä tuotetta.');
            }
        }
    };

    return (
        <div className="bg-gray-900 min-h-screen flex flex-col ">
            <div className="bg-gray-900  p-6 h-full w-full">
                <h3 className="text-white text-lg font-semibold mb-4">
                    {initialAccountId && accounts.length > 0
                        ? `Lisää uusi tuote tilille '${accounts[0].name}'`
                        : 'Lisää uusi tuote'}
                </h3>
                {error && <p className="text-red-500 mb-2">{error}</p>}
                {successMessage && <p className="text-green-500 mb-2">{successMessage}</p>}
                <select
                    name="role"
                    value={newProduct.role}
                    onChange={handleInputChange}
                    className="block w-full mb-3 p-2 rounded bg-gray-900 text-white placeholder-gray-500"
                >
                    <option value="">Valitse rooli</option>
                    <option value="admin">Admin</option>
                    <option value="user">User</option>
                    <option value="laravel">Laravel</option>
                    <option value="node">Node</option>
                    <option value="python">Python</option>
                    <option value="react">React</option>
                    <option value="back">Back</option>
                    <option value="front">Front</option>
                    <option value="full">Full</option>
                    <option value="affiliate">Affiliate</option>
                </select>
                <input
                    type="text"
                    name="name"
                    placeholder="Nimi"
                    value={newProduct.name}
                    onChange={handleInputChange}
                    className="block w-full mb-3 p-2 rounded bg-gray-700 text-black placeholder-gray-500"
                />
                <input
                    type="number"
                    name="price"
                    placeholder="Hinta x 100"
                    value={newProduct.price}
                    onChange={handleInputChange}
                    className="block w-full mb-3 p-2 rounded bg-gray-700 text-black placeholder-gray-500"
                />
                <input
                    type="text"
                    name="currency"
                    placeholder="Valuutta"
                    value={newProduct.currency}
                    onChange={handleInputChange}
                    className="block w-full mb-3 p-2 rounded bg-gray-700 text-black placeholder-gray-500"
                />
                <textarea
                    name="description"
                    placeholder="Kuvaus"
                    value={newProduct.description}
                    onChange={handleInputChange}
                    className="block w-full mb-3 p-2 rounded bg-gray-700 text-black placeholder-gray-500"
                />
                <input
                    type="text"
                    name="features"
                    placeholder="Ominaisuudet (pilkulla erotettu)"
                    value={newProduct.features}
                    onChange={handleInputChange}
                    className="block w-full mb-3 p-2 rounded bg-gray-700 text-black placeholder-gray-500"
                />
                <label className="text-white mb-3">
                    <input
                        type="checkbox"
                        name="popular"
                        checked={newProduct.popular}
                        onChange={handleCheckboxChange}
                        className="mr-2"
                    />
                    Suosittu tuote
                </label>
                <button
                    onClick={handleAddProduct}
                    className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-400 transition-colors duration-200"
                >
                    Lisää Tuote
                </button>
            </div>
        </div>
    );
}

export default AdminAddProduct;