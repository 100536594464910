import React from 'react';
import FieldBuilder from './FieldBuilder';

const TableBuilder = ({ index, table, updateTable, removeTable, tables }) => {
    // Generic handler for updating table properties
    const handleChange = (property, value) => {
        const updatedTable = { ...table, [property]: value };
        updateTable(index, updatedTable);
    };

    // Handlers for specific input types
    const handleInputChange = (e) => handleChange(e.target.name, e.target.value);
    const handleCheckboxChange = (e) => handleChange(e.target.name, e.target.checked);

    // Handlers for fields
    const addField = () => {
        const newField = {
            id: Date.now(), // Unique ID for better key management
            name: '',
            type: 'string',
            nullable: false,
            default: '',
            fillable: true,
            cast: false,
            options: [], // For enum types
        };
        const updatedFields = [...table.fields, newField];
        handleChange('fields', updatedFields);
    };

    const addForeignKey = () => {
        const newForeignKey = {
            id: Date.now(),
            type: 'foreignId',
            name: '', // e.g., user_id
            references: '',
            onDelete: 'cascade',
            nullable: false,
            fillable: true,
            cast: false,
        };
        const updatedFields = [...table.fields, newForeignKey];
        handleChange('fields', updatedFields);
    };

    const removeField = (fieldId) => {
        const updatedFields = table.fields.filter((field) => field.id !== fieldId);
        handleChange('fields', updatedFields);
    };

    const updateField = (fieldId, newField) => {
        const updatedFields = table.fields.map((field) =>
            field.id === fieldId ? newField : field
        );
        handleChange('fields', updatedFields);
    };

    // Handler to toggle soft deletes
    const toggleSoftDeletes = (e) => {
        handleCheckboxChange({ target: { name: 'softDeletes', checked: e.target.checked } });
    };

    // Handler to toggle primary key
    const togglePrimaryKey = (e) => {
        handleCheckboxChange({ target: { name: 'primaryKey', checked: e.target.checked } });
    };

    return (
        <div className='table-container p-6 mb-6 border rounded-md bg-gray-900'>
            <div className='flex justify-between items-center mb-4'>
                <div className='w-full'>
                    <label className='block text-sm font-medium text-gray-300'>Table Name</label>
                    <input
                        name="name"
                        className='w-full p-2 mt-1 rounded-md bg-gray-800 text-black border border-gray-600'
                        type="text"
                        value={table.name}
                        onChange={handleInputChange}
                        placeholder="Enter table name"
                        required
                    />
                </div>
                <button
                    type="button"
                    className='ml-4 bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600'
                    onClick={() => removeTable(index)}
                    aria-label="Remove Table"
                >
                    Remove Table
                </button>
            </div>

            {/* Table Description
            <div className='mb-4'>
                <label className='block text-sm font-medium text-gray-300'>Table Description</label>
                <textarea
                    name="description"
                    className='w-full h-50px p-2 mt-1 rounded-md bg-gray-800 text-white border border-gray-600'
                    value={table.description || ''}
                    onChange={handleInputChange}
                    placeholder="Enter table description"
                />
            </div> */}

            {/* Fields */}
            <div className='mb-4'>
                <h3 className='text-lg font-semibold text-gray-300'>Fields for {table.name || 'Table'}:</h3>
                {table.fields.length === 0 && (
                    <p className='text-gray-500'>No fields added yet. Click "Add Field" to start.</p>
                )}
                <div className='space-y-4 mt-2'>
                    {table.fields.map((field) => (
                        <FieldBuilder
                            key={field.id}
                            field={field}
                            updateField={(newField) => updateField(field.id, newField)}
                            removeField={() => removeField(field.id)}
                            tables={tables.filter(t => t.id < table.id)} // Assuming table.id is defined
                        />
                    ))}
                </div>
            </div>

            {/* Additional Table Options */}
            <div className='mb-4 grid grid-cols-1 md:grid-cols-2 gap-4'>
                {/* Timestamps */}
                <div className='flex items-center'>
                    <input
                        id={`timestamps-${index}`}
                        name="timestamps"
                        type="checkbox"
                        checked={table.timestamps || false}
                        onChange={handleCheckboxChange}
                        className='h-4 w-4 text-blue-600 border-gray-300 rounded'
                    />
                    <label htmlFor={`timestamps-${index}`} className='ml-2 block text-sm text-gray-300'>
                        Timestamps
                    </label>
                </div>

                {/* Soft Deletes */}
                <div className='flex items-center'>
                    <input
                        id={`softDeletes-${index}`}
                        name="softDeletes"
                        type="checkbox"
                        checked={table.softDeletes || false}
                        onChange={toggleSoftDeletes}
                        className='h-4 w-4 text-blue-600 border-gray-300 rounded'
                    />
                    <label htmlFor={`softDeletes-${index}`} className='ml-2 block text-sm text-gray-300'>
                        Soft Deletes
                    </label>
                </div>

                {/* Primary Key */}
                <div className='flex items-center'>
                    <input
                        id={`primaryKey-${index}`}
                        name="primaryKey"
                        type="checkbox"
                        checked={table.primaryKey || false}
                        onChange={togglePrimaryKey}
                        className='h-4 w-4 text-blue-600 border-gray-300 rounded'
                    />
                    <label htmlFor={`primaryKey-${index}`} className='ml-2 block text-sm text-gray-300'>
                        Primary Key
                    </label>
                </div>
            </div>

            {/* Table Actions */}
            <div className='flex space-x-4 mt-4'>
                <button
                    type="button"
                    className='bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600'
                    onClick={addField}
                >
                    Add Field
                </button>
                <button
                    type="button"
                    className='bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600'
                    onClick={addForeignKey}
                >
                    Add Foreign Key
                </button>
                <button
                    type="button"
                    className='bg-yellow-500 text-white px-4 py-2 rounded-md hover:bg-yellow-600'
                    onClick={() => alert('Duplicate Table feature coming soon!')}
                >
                    Duplicate Table
                </button>
            </div>
        </div>
    );

};

export default TableBuilder;
