import React, { useState } from 'react';
import axiosInstance from '../../axios';

const TableForm = () => {
  const [headers, setHeaders] = useState(['']);
  const [generatedCode, setGeneratedCode] = useState('');
  const [notification, setNotification] = useState({ message: '', type: '' });

  const handleChange = (index, event) => {
    const newHeaders = [...headers];
    newHeaders[index] = event.target.value;
    setHeaders(newHeaders);
  };

  const handleAddHeader = () => {
    setHeaders([...headers, '']);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axiosInstance.post('/user/reacTtable', { columns: headers });
      setGeneratedCode(response.data.code);
      setHeaders(['']);
      setNotification({ message: 'Table generated successfully!', type: 'success' });
    } catch (error) {
      setNotification({ message: 'Error generating table. Please try again.', type: 'error' });
      console.error('Error generating table:', error);
    }

    setTimeout(() => {
      setNotification({ message: '', type: '' });
    }, 3000);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(generatedCode).then(() => {
      setNotification({ message: 'Code copied to clipboard!', type: 'success' });
    }).catch((err) => {
      setNotification({ message: 'Failed to copy text. Please try again.', type: 'error' });
      console.error('Failed to copy text:', err);
    });

    setTimeout(() => {
      setNotification({ message: '', type: '' });
    }, 3000);
  };

  return (
    <div className="kotipituus bg-gray-900 py-10 w-full">
      {notification.message && (
        <div
          className={`mb-4 p-4 rounded-md ${
            notification.type === 'success' ? 'bg-green-500' : 'bg-red-500'
          } text-white`}
        >
          {notification.message}
        </div>
      )}
      <form onSubmit={handleSubmit}>
        {headers.map((header, index) => (
          <div key={index}>
            <label>
              Header {index + 1}:
              <input className='text-black' type="text" value={header} onChange={(e) => handleChange(index, e)} />
            </label>
          </div>
        ))}
        <button type="button" onClick={handleAddHeader}>Add Another Header</button>
        <button type="submit">Generate Table</button>
      </form>
      {generatedCode && (
        <div className='kotipituus'>
          <div className='reactEditorContent'>
            <h3>Generated Table File</h3>
            <textarea
              readOnly
              value={generatedCode}
              rows="15"
              cols="80"
            />
            <button onClick={handleCopy}>Copy</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TableForm;
