import React from 'react';
import FieldBuilder from './FieldBuilder';

const TableBuilder = ({ index, table, updateTable, removeTable, tables }) => {
    const updateTableName = (e) => {
        const newTable = { ...table, name: e.target.value };
        updateTable(index, newTable);
    };

    const updateTimestamps = (e) => {
        const newTable = { ...table, timestamps: e.target.checked };
        updateTable(index, newTable);
    };

    const addField = () => {
        const newField = { name: '', type: 'String', required: false, unique: false, default: '' };
        const newTable = { ...table, fields: [...table.fields, newField] };
        updateTable(index, newTable);
    };

    const addForeignKey = () => {
        const newField = { type: 'Reference', reference: '', onDelete: 'cascade', required: false };
        const newTable = { ...table, fields: [...table.fields, newField] };
        updateTable(index, newTable);
    };

    const removeField = (fieldIndex) => {
        const newFields = table.fields.filter((_, i) => i !== fieldIndex);
        const newTable = { ...table, fields: newFields };
        updateTable(index, newTable);
    };

    const updateField = (fieldIndex, newField) => {
        const newFields = [...table.fields];
        newFields[fieldIndex] = newField;
        const newTable = { ...table, fields: newFields };
        updateTable(index, newTable);
    };

    return (
        <div className='tablediv max-w-full'>
            <p className='tablename'>Table name</p>
            <input
                className='w-full max-w-full sm:max-w-xs p-2 rounded-md bg-gray-700 text-black border border-gray-600'
                type="text"
                value={table.name}
                onChange={updateTableName}
                placeholder="Table Name"
            />
            <p className='tablename'>Fields for {table.name} table:</p>
            <div className='tableflex flex-wrap'>
                {table.fields.map((field, fieldIndex) => (
                    <FieldBuilder
                        key={fieldIndex}
                        field={field}
                        updateField={(newField) => updateField(fieldIndex, newField)}
                        removeField={() => removeField(fieldIndex)}
                        tables={tables.filter(t => t.id < table.id)}
                    />
                ))}
            </div>
            <div className='flex items-center space-x-2 mt-4'>
                <label className='flex items-center space-x-2'>
                    <input type="checkbox" checked={table.timestamps || false} onChange={updateTimestamps} />
                    <span>Timestamps</span>
                </label>
            </div>
            <div className='flex space-x-2 mt-4'>
                <button className='bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600' onClick={addField}>Add Field</button>
                <button className='bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600' onClick={addForeignKey}>Add Foreign Key</button>
                <button className='bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600' onClick={() => removeTable(index)}>Remove Table</button>
            </div>
        </div>
    );
};

export default TableBuilder;