import React, { useState, useEffect } from 'react';
import './index.css';
import AdminDashboard from './views/dashboards/adminDashboard';
import UserDashboard from './views/dashboards/userDashboard';
import LandingPage from './Landing/LandingPage';
import Navbar from './Landing/LandingNavBar';
import SignIn from './login/AdminLoginMui';
import SignInUser from './login/UserLoginMui';
import SignUp from './login/MuiRegister';
import ProductLandingPage from './Landing/ProductLandingPage';
import AffiliateLandingPage from './Landing/Affiliate';
import Footer from './Footer';
import LaravelBlogPost from './Landing/LaravelBlog';
import PythonBlogPost from './Landing/PythonBlog';
import ExpressBlogPost from './Landing/ExpressBlog';
import ResetPassword from './login/ResetPassword';
import { HelmetProvider } from 'react-helmet-async';

function App() {
  const [selectedLogin, setSelectedLogin] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [bgClass, setBgClass] = useState('home');

  // Clear local and session storage when user leaves or reloads the page
  window.addEventListener('beforeunload', function () {
    // Clear local storage
    localStorage.clear();
    
    // Clear session storage
    sessionStorage.clear();
  });

  // Clear cookies when user leaves or reloads the page
  window.addEventListener('beforeunload', function () {
    // Clear cookies
    document.cookie.split(";").forEach(function(c) {
        document.cookie = c.trim().split("=")[0] + "=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
    });
  });

  useEffect(() => {
    const token = localStorage.getItem('token');
    const savedLoginType = localStorage.getItem('loginType');

    if (token && savedLoginType) {
      setIsLoggedIn(true);
      setSelectedLogin(savedLoginType);
      setBgClass('bg-home'); // Set the background class when logged in
    }
  }, []);

  const handleLoginSelection = (loginType) => {
    setSelectedLogin(loginType);
    setTimeout(() => {
      setSelectedLogin(loginType);
      if (loginType === 'home') {
        setSelectedLogin('notHome'); // Or handle this according to your logic
      }
    }, 0);
  };

  const handleLoginSuccess = () => {
    const isAdmin = selectedLogin === 'admin';
    localStorage.setItem('loginType', selectedLogin);
    localStorage.setItem('admin', isAdmin.toString());
    setIsLoggedIn(true);
    setBgClass('bg-home'); // Set the background class when logged in
  };

  /* const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('loginType');
    localStorage.removeItem('admin');
    setIsLoggedIn(false);
    setSelectedLogin('');
    setBgClass(''); // Remove the background class when logged out
  }; */

  return (
    <HelmetProvider>
    <div className={`${isLoggedIn ? `min-h-screen ${bgClass}` : ''}`}>
      {!isLoggedIn && (
        <Navbar handleLoginSelection={handleLoginSelection} />
      )}
      
      <div className={isLoggedIn ? "flex-grow container mx-auto px-4 sm:px-6 lg:px-8" : ""}>
        {isLoggedIn ? (
          <>
            {selectedLogin === 'admin' && <AdminDashboard />}
            {selectedLogin === 'user' && <UserDashboard setBgClass={setBgClass} />}
          </>
        ) : (
          <>
            {selectedLogin ? (
              <>
                {selectedLogin === 'signUp' && <SignUp handleLoginSelection={handleLoginSelection} />}
                {selectedLogin === 'resetPassword' && <ResetPassword handleLoginSelection={handleLoginSelection} />}
                {selectedLogin === 'admin' && <SignIn onLoginSuccess={handleLoginSuccess} />}
                {selectedLogin === 'user' && <SignInUser onLoginSuccess={handleLoginSuccess} handleLoginSelection={handleLoginSelection}/>}                
                {selectedLogin === 'home' && <LandingPage onLoginSuccess={handleLoginSuccess} />}
                {selectedLogin === 'notHome' && <LandingPage onLoginSuccess={handleLoginSuccess} handleLoginSelection={handleLoginSelection}/>}
                {selectedLogin === 'affiliate' && <AffiliateLandingPage onLoginSuccess={handleLoginSuccess} handleLoginSelection={handleLoginSelection}/>}
                {selectedLogin === 'laravel' && <LaravelBlogPost onLoginSuccess={handleLoginSuccess} handleLoginSelection={handleLoginSelection}/>}
                {selectedLogin === 'python' && <PythonBlogPost onLoginSuccess={handleLoginSuccess} handleLoginSelection={handleLoginSelection}/>}
                {selectedLogin === 'express' && <ExpressBlogPost onLoginSuccess={handleLoginSuccess} handleLoginSelection={handleLoginSelection}/>}
              </>
            ) : (
              <LandingPage handleLoginSelection={handleLoginSelection} />
            )}
          </>
        )}
      </div>
      <Footer></Footer>
    </div>
    </HelmetProvider>
  );
}

export default App;