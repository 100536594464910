import React, { useState } from 'react';
import AdminAddUser from '../Admin/adminAddUser';
import AdminManageUsers from '../Admin/adminManageUsers';
import AdminManageProducts from '../Admin/adminManageProducts';
import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import AdminAddProduct from '../Admin/adminAddProducts';
//import { ChevronDownIcon } from '@heroicons/react/solid';



function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function AdminDashboard() {
  const [activeView, setActiveView] = useState('');

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('loginType'); // Clear the login type
    localStorage.removeItem('admin')
    window.location.reload();
  };

  useState(() => {
    setActiveView('manageUsers');
  }, []);

  const handleViewToggle = (view) => {

    setActiveView(activeView === view ? view : view); 
  };

  return (
    <div className="min-h-full bg-gray-800">
      <Disclosure as="nav" className="bg-gray-800">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="flex h-16 items-center justify-between">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <img
                      className="h-8 w-auto"
                      src="https://picsum.photos/200/400"
                      alt="profile"
                    />
                  </div>
                  <div className="sm:ml-6 flex flex-col sm:flex-row">
                    <div className="flex space-x-4">
                      <button
                        className="inline-flex items-center rounded-md bg-gray-900 px-3 py-2 text-sm font-medium text-white hover:bg-gray-700 focus:outline-none"
                        onClick={() => handleViewToggle('manageUsers')}
                      >
                        Käyttäjät
                      </button>
                      <button
                        className="inline-flex items-center rounded-md bg-gray-900 px-3 py-2 text-sm font-medium text-white hover:bg-gray-700 focus:outline-none"
                        onClick={() => handleViewToggle('manageProducts')}
                      >
                        Tuotteiden hallinta
                      </button>
                      <button
                        className="inline-flex items-center rounded-md bg-gray-900 px-3 py-2 text-sm font-medium text-white hover:bg-gray-700 focus:outline-none"
                        onClick={() => handleViewToggle('addProducts')}
                      >
                        Tuotteiden Lisääminen
                      </button>
                    </div>
                  </div>
                </div>
                <div className="sm:ml-6 flex flex-col sm:flex-row">
                  <div className="flex items-center">


                    {/* Profile dropdown */}
                    <Menu as="div" className="relative ml-3">
                      <div>
                        <Menu.Button className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                          <span className="absolute -inset-1.5" />
                          <span className="sr-only">Open user menu</span>
                          <img
                            className="h-8 w-8 rounded-full"
                            src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                            alt=""
                          />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <Menu.Item>
                            {({ active }) => (
                              <a onClick={handleLogout}
                                href=''
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block px-4 py-2 text-sm text-gray-700'
                                )}
                              >
                                Sign out
                              </a>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>

              </div>
            </div>
          </>
        )}
      </Disclosure>


      <main>
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-6">
          <div className="flex flex-wrap md:flex-nowrap">
            {/* Conditional rendering for Users */}
            {activeView === 'manageUsers' && (
              <div className="w-full">
                <AdminManageUsers />
              </div>
            )}
            {activeView === 'addUser' && (
              <div className="w-full">
                <AdminAddUser />
              </div>
            )}
            {activeView === 'manageProducts' && (
              <div className="w-full">
                <AdminManageProducts />
              </div>
            )}
            {activeView === 'addProducts' && (
              <div className="w-full">
                <AdminAddProduct />
              </div>
            )}
          </div>
        </div>
      </main>
    </div>
  );
}

export default AdminDashboard;
