import React, { useState, useEffect, useRef } from 'react';
import NodeMigrate from './userGenerateNodeMigration';
import ControllerEditor from './userNodeControllerEditor';
import ModelEditor from './userNodeModelEditor';
import ValidationEditor from './userNodeValidationEditor';
import RouteEditor from './userNodeRouteEditor';
import { Disclosure } from '@headlessui/react';

function NodeMainComponent() {
    const [activeComponent, setActiveComponent] = useState('NodeMainComponent');

    const handleViewToggle = (view, close) => {
        setActiveComponent(view);
        close();
    };

    return (
        <div className="bg-gray-900 w-full min-h-screen">
            <div className="mx-auto max-w-7xl w-full bg-gray-900">
                <Disclosure as="nav">
                    {({ open, close }) => (
                        <>
                            <div className="bg-gray-800 p-0 m-0 relative top-0 left-0 w-full">
                                <div className="flex items-center justify-between p-4">
                                    <Disclosure.Button className="inline-flex items-center rounded-md bg-gray-900 px-3 py-2 text-sm font-medium text-white hover:bg-gray-700 focus:outline-none lg:hidden">
                                       Express.js Menu
                                    </Disclosure.Button>
                                    <div className="hidden lg:flex space-x-6">
                                        <button className="text-white hover:bg-gray-700 font-semibold w-full" onClick={() => handleViewToggle('NodeMainComponent', close)}>
                                        Express.js Home
                                        </button>
                                        <button className="text-white hover:bg-gray-700 font-semibold w-full" onClick={() => handleViewToggle('modifyController', close)}>
                                            Modify controller template
                                        </button>
                                        <button className="text-white hover:bg-gray-700 font-semibold w-full" onClick={() => handleViewToggle('modifyModel', close)}>
                                            Modify model template
                                        </button>
                                        <button className="text-white hover:bg-gray-700 font-semibold w-full" onClick={() => handleViewToggle('modifyValidation', close)}>
                                            Modify validation rule template
                                        </button>
                                        <button className="text-white hover:bg-gray-700 font-semibold w-full" onClick={() => handleViewToggle('modifyRoute', close)}>
                                            Modify routes template
                                        </button>
                                        <button className="text-white hover:bg-gray-700 font-semibold w-full" onClick={() => handleViewToggle('migration', close)}>
                                            Generate code
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <Disclosure.Panel className="lg:hidden">
                                <div className="space-y-1 px-2 pb-3 pt-2">
                                    <button className="block w-full text-left px-3 py-2 rounded-md text-sm font-medium text-white bg-gray-900 hover:bg-gray-700 focus:outline-none" onClick={() => handleViewToggle('NodeMainComponent', close)}>
                                    Express.js Home
                                    </button>
                                    <button className="block w-full text-left px-3 py-2 rounded-md text-sm font-medium text-white bg-gray-900 hover:bg-gray-700 focus:outline-none" onClick={() => handleViewToggle('modifyController', close)}>
                                        Modify controller template
                                    </button>
                                    <button className="block w-full text-left px-3 py-2 rounded-md text-sm font-medium text-white bg-gray-900 hover:bg-gray-700 focus:outline-none" onClick={() => handleViewToggle('modifyModel', close)}>
                                        Modify model template
                                    </button>
                                    <button className="block w-full text-left px-3 py-2 rounded-md text-sm font-medium text-white bg-gray-900 hover:bg-gray-700 focus:outline-none" onClick={() => handleViewToggle('modifyValidation', close)}>
                                        Modify validation rule template
                                    </button>
                                    <button className="block w-full text-left px-3 py-2 rounded-md text-sm font-medium text-white bg-gray-900 hover:bg-gray-700 focus:outline-none" onClick={() => handleViewToggle('modifyRoute', close)}>
                                        Modify routes template
                                    </button>
                                    <button className="block w-full text-left px-3 py-2 rounded-md text-sm font-medium text-white bg-gray-900 hover:bg-gray-700 focus:outline-none" onClick={() => handleViewToggle('migration', close)}>
                                        Generate code
                                    </button>
                                </div>
                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>

                {activeComponent === 'NodeMainComponent' && (
                    <div className="bg-gray-900 py-10 w-full">
                        <div className="px-4 sm:px-6 lg:px-8 w-full">
                            <div className="sm:flex sm:items-center">
                                <div className="sm:flex-auto">
                                <div class="bg-gray-800 p-6 rounded-lg shadow-lg text-white">
  <h2 class="text-2xl font-bold mb-4">Guide to Express.js File Generation and Customization</h2>
  <p class="mb-6">
    This guide explains how the Express.js file generation process works and how users can modify the generated files to suit their specific needs. It covers the functions that generate files and the customization options available through user-modifiable templates.
  </p>

  <h3 class="text-xl font-semibold mb-3">Overview of File Generation</h3>
  <p class="mb-4">
    The Express.js backend is set up to generate models, controllers, SQL migration files, and routes based on user input. This is done using a series of templates and predefined logic to automate the creation of common Express.js components.
  </p>
  <p class="mb-4">The generation process involves the following main steps:</p>
  <ol class="list-decimal list-inside mb-6 space-y-2">
    <li><strong>Generating Models, Controllers, and SQL Migration Files:</strong> Create new files using templates.</li>
    <li><strong>Creating Routes:</strong> Define API routes based on the generated components.</li>
    <li><strong>User Customization:</strong> Users can modify the templates to adjust the generated code to their requirements.</li>
  </ol>
  <p class="mb-6">
    The generation is initiated through user input via a frontend UI, which interacts with the backend using API calls.
  </p>

  <h3 class="text-xl font-semibold mb-3">File Generation Workflow</h3>
  <h4 class="text-lg font-semibold mb-2">1. Generating Files</h4>
  <p class="mb-4">The generating process takes user input and generates the necessary files:</p>
  <ul class="list-disc list-inside mb-6 space-y-2">
    <li><strong>Models</strong> are generated based on user-provided table definitions that determine the fields, types, and attributes.</li>
    <li><strong>Controllers</strong> are generated using a controller template to create new controller classes for each model.</li>
    <li><strong>SQL Migration Files</strong> define the structure of the database tables. Unlike Laravel, which generates migration scripts that use Eloquent syntax, this generator produces raw SQL files that directly define the necessary SQL commands.</li>
    <li><strong>Routes</strong> are generated to create appropriate route definitions for each controller.</li>
  </ul>

  <h3 class="text-xl font-semibold mb-3">Customizing Generated Files</h3>
  <p class="mb-6">
    Users can customize the templates used for generating models, controllers, SQL migration files, and routes. This flexibility ensures the generated files meet specific requirements. Below, you'll find details on each customization point.
  </p>

  <h4 class="text-lg font-semibold mb-3">Customizable Templates and Editors</h4>
  <h5 class="font-semibold mb-2">1. Model Editor</h5>
  <p class="mb-4">
    This editor allows users to modify the model template. You can update the <code>fillable</code> properties, validation rules, and more to meet your database requirements.
  </p>
  <ul class="list-disc list-inside mb-6 space-y-2">
    <li><strong>Key Features:</strong>
      <ul class="list-disc ml-6 space-y-1">
        <li>Edit the default model template to add/remove fields or change validation rules.</li>
        <li>Set the default model template back if needed.</li>
      </ul>
    </li>
    <li><strong>How to Use:</strong>
      <ul class="list-disc ml-6 space-y-1">
        <li>Access the model template by navigating to the ModelEditor interface.</li>
        <li>Modify the content in the provided text area and click <strong>Save</strong> to update the file.</li>
        <li>If you need to revert, click <strong>Set default model template</strong>.</li>
      </ul>
    </li>
  </ul>

  <h5 class="font-semibold mb-2">2. Controller Editor</h5>
  <p class="mb-4">
    Users can modify the controller template, allowing changes to how each model is handled.
  </p>
  <ul class="list-disc list-inside mb-6 space-y-2">
    <li><strong>Key Features:</strong>
      <ul class="list-disc ml-6 space-y-1">
        <li>Edit default CRUD methods or add custom actions.</li>
        <li>Revert to the default controller template.</li>
      </ul>
    </li>
    <li><strong>How to Use:</strong>
      <ul class="list-disc ml-6 space-y-1">
        <li>Access the controller editor via the ControllerEditor interface.</li>
        <li>Make desired changes and click <strong>Save</strong>.</li>
        <li>Revert to the default by clicking <strong>Set default controller template</strong>.</li>
      </ul>
    </li>
  </ul>

  <h5 class="font-semibold mb-2">3. Route Editor</h5>
  <p class="mb-4">Allows editing of route definitions. Users can add, remove, or modify the routes.</p>
  <ul class="list-disc list-inside mb-6 space-y-2">
    <li><strong>How to Use:</strong>
      <ul class="list-disc ml-6 space-y-1">
        <li>Modify the routes template by using the RouteEditor interface.</li>
        <li>Click <strong>Save</strong> to apply the changes or <strong>Set default routes template</strong> to revert.</li>
      </ul>
    </li>
  </ul>

  <h5 class="font-semibold mb-2">4. Validation Editor</h5>
  <p class="mb-4">The ValidationEditor allows users to define validation rules for model fields.</p>
  <ul class="list-disc list-inside mb-6 space-y-2">
    <li><strong>Key Features:</strong>
      <ul class="list-disc ml-6 space-y-1">
        <li>Define specific validation for each field type (e.g., <code>required</code>, <code>string</code>, <code>email</code>).</li>
        <li>Revert to default validation rules.</li>
      </ul>
    </li>
    <li><strong>How to Use:</strong>
      <ul class="list-disc ml-6 space-y-1">
        <li>Modify the validation rules via the editor.</li>
        <li>Save changes or revert using the provided buttons.</li>
      </ul>
    </li>
  </ul>

  <h3 class="text-xl font-semibold mb-3">Generating and Downloading Files</h3>
  <h4 class="text-lg font-semibold mb-2">1. Generate Express.js Code</h4>
  <p class="mb-4">
    From the main UI, users can click <strong>Generate Code</strong> to trigger backend generation of models, controllers, SQL migration files, and routes. This process sends the table definitions to the backend for processing.
  </p>

  <h4 class="text-lg font-semibold mb-2">2. Download Generated Code</h4>
  <p class="mb-4">
    After the files are generated, users can click <strong>Download Code</strong> to download all generated files as a ZIP archive.
  </p>
  <ul class="list-disc list-inside mb-6 space-y-2">
    <li><strong>How to Use:</strong>
      <ul class="list-disc ml-6 space-y-1">
        <li>Click <strong>Generate Code</strong> to create the files.</li>
        <li>Once complete, click <strong>Download Code</strong> to get a ZIP of all the generated files.</li>
      </ul>
    </li>
  </ul>

  <h3 class="text-xl font-semibold mb-3">Summary</h3>
  <p class="mb-6">
    This system provides an efficient way to generate Express.js backend components using customizable templates. Users can modify the default templates to ensure generated code matches their requirements. The provided editors make customization accessible, while the React UI allows for seamless generation and management of Express.js components.
  </p>
  <p class="font-semibold mb-4">Remember:</p>
  <ul class="list-disc list-inside mb-4 space-y-2">
    <li>Always save changes after modifying templates.</li>
    <li>Revert to default templates if unexpected behavior occurs.</li>
    <li>You can easily download all generated files for use in your projects.</li>
  </ul>
</div>


                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {activeComponent === 'modifyController' && <ControllerEditor />}
                {activeComponent === 'modifyModel' && <ModelEditor />}
                {activeComponent === 'modifyValidation' && <ValidationEditor />}
                {activeComponent === 'modifyRoute' && <RouteEditor />}
                {activeComponent === 'migration' && <NodeMigrate />}
            </div>
        </div>
    );
}

export default NodeMainComponent;