import React, { useState } from 'react';
import axiosInstance from '../../axios';

const NavbarForm = () => {
  const [links, setLinks] = useState(['']);
  const [generatedCode, setGeneratedCode] = useState('');
  const [notification, setNotification] = useState({ message: '', type: '' });

  const handleChange = (index, event) => {
    const newLinks = [...links];
    newLinks[index] = event.target.value;
    setLinks(newLinks);
  };

  const handleAddLink = () => {
    setLinks([...links, '']);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axiosInstance.post('/user/reacTnavbar', { links });
      setGeneratedCode(response.data.code);
      setNotification({ message: 'Navbar generated successfully!', type: 'success' });
    } catch (error) {
      setNotification({ message: 'Error generating navbar. Please try again.', type: 'error' });
      console.error('Error generating navbar:', error);
    }

    setTimeout(() => {
      setNotification({ message: '', type: '' });
    }, 3000);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(generatedCode).then(() => {
      setNotification({ message: 'Code copied to clipboard!', type: 'success' });
    }).catch((err) => {
      setNotification({ message: 'Failed to copy text. Please try again.', type: 'error' });
      console.error('Failed to copy text:', err);
    });

    setTimeout(() => {
      setNotification({ message: '', type: '' });
    }, 3000);
  };

  return (
    <div className="kotipituus bg-gray-900 w-full">
      {notification.message && (
        <div
          className={`mb-4 p-4 rounded-md ${
            notification.type === 'success' ? 'bg-green-500' : 'bg-red-500'
          } text-white`}
        >
          {notification.message}
        </div>
      )}
      <form onSubmit={handleSubmit}>
        {links.map((link, index) => (
          <div key={index}>
            <label>
              Link {index + 1}:
              <input className='text-black' type="text" value={link} onChange={(e) => handleChange(index, e)} />
            </label>
          </div>
        ))}
        <button type="button" onClick={handleAddLink}>Add Another Link</button>
        <button type="submit">Generate Navbar</button>
      </form>
      {generatedCode && (
        <div className='bg-gray-900 w-full'>
          <div className='reactEditorContent'>
            <h2>Generated Navbar Code</h2>
            <textarea readOnly value={generatedCode} rows="15" cols="80" />
            <button onClick={handleCopy}>Copy</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default NavbarForm;
