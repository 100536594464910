import React, { useState } from 'react';
import axiosInstance from '../../axios';

function AdminAddUser({ initialAccountId = '' }) {
    const [newUser, setNewUser] = useState({
        name: '',
        email: '',
        role: '',
        affiliate_coupon: '',
        registeration_coupon: '',
        license_ends: '',
        generations: ''
    });
    const [error, setError] = useState('');
    const [accounts] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewUser({ ...newUser, [name]: value });
    };

    const handleAddUser = async () => {
        try {
            let payload = { ...newUser };

            const response = await axiosInstance.post('admin/createUser', payload);
            setNewUser({ name: '', email: '', role: ''});
            setError('');
            setSuccessMessage('Käyttäjä lisätty onnistuneesti');
            console.log(response);
        } catch (error) {
            console.log('Error adding user:', error);
            if (error.response && error.response.data && error.response.data.error) {
                setError(error.response.data.error.message);
                if (error.response.data.error.message.includes('SQLSTATE[23505]')) {
                    setError('Sähköposti on jo käytössä.');
                }
            } else {
                setError('Virhe lisättäessä käyttäjää.');
            }
        }
    };

    return (
        <div className="bg-gray-900 min-h-screen flex flex-col ">
            <div className="bg-gray-900  p-6 h-full w-full">
                <h3 className="text-white text-lg font-semibold mb-4">
                    {initialAccountId && accounts.length > 0
                        ? `Lisää uusi käyttäjä tilille '${accounts[0].name}'`
                        : 'Lisää uusi käyttäjä'}
                </h3>
                {error && <p className="text-red-500 mb-2">{error}</p>}
                {successMessage && <p className="text-green-500 mb-2">{successMessage}</p>}
                <input
                    type="text"
                    name="name"
                    placeholder="Nimi"
                    value={newUser.name}
                    onChange={handleInputChange}
                    className="block w-full mb-3 p-2 rounded bg-gray-700 text-black placeholder-gray-500"
                />
                <input
                    type="email"
                    name="email"
                    placeholder="Sähköposti"
                    value={newUser.email}
                    onChange={handleInputChange}
                    className="block w-full mb-3 p-2 rounded bg-gray-700 text-black placeholder-gray-500"
                />
                <select
                    name="role"
                    value={newUser.role}
                    onChange={handleInputChange}
                    className="block w-full mb-3 p-2 rounded bg-gray-900 text-white placeholder-gray-500"
                >
                    <option value="">Valitse rooli</option>
                    <option value="admin">Admin</option>
                    <option value="user">User</option>
                    <option value="laravel">Laravel</option>
                    <option value="node">Node</option>
                    <option value="python">Python</option>
                    <option value="react">React</option>
                    <option value="back">Back</option>
                    <option value="front">Front</option>
                    <option value="full">Full</option>
                    <option value="affiliate">Affiliate</option>
                </select>
               

                <button
                    onClick={handleAddUser}
                    className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-400 transition-colors duration-200"
                >
                    Lisää käyttäjä
                </button>
            </div>
        </div>
    );
}

export default AdminAddUser;