import React from 'react';
import './LaravelBlog.css';


const LaravelBlogPost =({ handleLoginSelection }) => {
  const handleLinkClick = (selection) => {
    handleLoginSelection(selection);
    const navbarLinks = document.querySelector('.linkki');
    navbarLinks.classList.remove('active');
  };
  return (
    <div className="laravel-blog-container">
      <h1 className="laravel-main-headline">
        Accelerate your Laravel backend development with SnappCode: Open the door for Rapid, Efficient Solutions
      </h1>


      <div className="laravel-section">
        
        <div className="laravel-image"></div>
        <div className="laravel-section-content">
        
  
         {/*  <p className="laravel-section-paragraph">
            Before diving into how SnappCode can transform your workflow, it's essential to understand why Laravel stands out as a top choice for backend development.
          </p> */}
          <p className="laravel-section-paragraph">
  In the ever-evolving landscape of web development, speed and efficiency are paramount. Laravel, a powerful PHP framework, has long been a favorite among developers for building robust and scalable backends. Leveraging tools like SnappCode can further enhance your Laravel projects by streamlining the backend development process through the automatic generation of migration files, routes, controllers, and models. This approach not only boosts Laravel's capabilities but also highlights the benefits of using Laravel and uncovers the endless possibilities it offers for modern web applications.
</p>

          <ul className="laravel-section-list">
          <li className="laravel-section-list-item">
            <p className="laravel-section-paragraph">
                Laravel's expressive syntax and well-structured framework make development both accessible and enjoyable. Its intuitive nature allows developers to progress rapidly, without sacrificing scalability or robustness, making it an ideal choice for projects of all sizes.
            </p>
            </li>
            <li className="laravel-section-list-item">
            <p className="laravel-section-paragraph">
                Additionally, Laravel comes with built-in features like authentication, routing, and caching, which streamline common tasks. Combined with extensive documentation and a supportive community, these tools enable developers to focus on building unique, innovative aspects of their applications while feeling confident in their ability to solve issues and continuously improve.
            </p>
            </li>


          </ul>
        </div>
      </div>

      <div className="laravel-centered-section">
      <button
          type="submit"
          className="linkki bg-blue-600 hover:bg-blue-500 text-white font-bold py-4 px-10 mt-4 mb-8 rounded-lg shadow-lg transition duration-300"
          onClick={() => handleLinkClick('signUp')}
        >
          Sign Up!
        </button>
        <h2 className="laravel-centered-headline">Unleash the Power of SnappCode for Laravel Development</h2>
        <p className="laravel-centered-paragraph">
          SnappCode is a game-changer for Laravel developers looking to accelerate their backend development process. By automating the generation of essential components, SnappCode minimizes repetitive tasks, allowing you to focus on crafting innovative features and enhancing your application's functionality.
        </p>
        <div className="laravel-image2"></div>
        <h3 className="laravel-centered-headline mt-12">Key Features of SnappCode with Laravel</h3>
        <ul className="laravel-centered-list mt-12">
          <li className="laravel-centered-list-item">
            <strong>Automatic Migration File Generation</strong>
            <p className="laravel-centered-paragraph">
              Migrations are the backbone of any Laravel application, defining the database schema
               and ensuring version control. SnappCode automates the creation of migration files, 
               reducing the time and effort required to set up your database structure.
            </p>
          </li>
          <li className="laravel-centered-list-item">
            <strong>Seamless Route Creation</strong>
            <p className="laravel-centered-paragraph">
              Defining routes is a critical step in connecting your application's endpoints to their 
              respective controllers. SnappCode streamlines this process by generating clean and 
              organized route definitions, ensuring consistency and reducing the likelihood of errors.
            </p>
          </li>
          <li className="laravel-centered-list-item">
            <strong>Efficient Controller Generation</strong>
            <p className="laravel-centered-paragraph">
              Controllers handle the logic behind your application's routes, managing
               requests and returning responses. With SnappCode, you can quickly generate controllers
                equipped with essential methods, accelerating the development of your application's core functionality.
            </p>
          </li>
          <li className="laravel-centered-list-item">
            <strong>Model Creation Made Easy</strong>
            <p className="laravel-centered-paragraph">
              Models represent the data and business logic of your application.
               SnappCode simplifies model creation by generating well-structured models, ensuring your data layer is robust and maintainable.
            </p>
          </li>
          <li className="laravel-centered-list-item">
            <strong>Customize Your Generations</strong>
            <p className="laravel-centered-paragraph">
            SnappCode gives you option to modify the templates that are used to build your generations. 
            You can affect the way validation rules, models, controllers and routes are built.  
            </p>
          </li>
        </ul>

        <button
          type="submit"
          className="linkki bg-green-600 hover:bg-green-500 text-white font-bold py-4 px-10 mt-4 rounded-lg shadow-lg transition duration-300"
          onClick={() => handleLinkClick('signUp')}
        >
          Create Account!
        </button>
      </div>
    </div>
  );
};

export default LaravelBlogPost;