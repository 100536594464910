import React, { useState, useEffect, useRef } from 'react';
import axiosInstance from '../../axios';
import UpdateUserForm from '../Admin/updateUserForm';
import AdminAddUser from './adminAddUser';

function AdminManageUsers() {
    const [users, setUsers] = useState([]);
    const [allAccounts, setAllAccounts] = useState([]);
    const [error, setError] = useState('');
    const [userIdToUpdate, setUserIdToUpdate] = useState(null);
    const [dropdownOpen, setDropdownOpen] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        role: '',
    });
    const [updateMessage, setUpdateMessage] = useState('');
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);
    const [userIdToDelete, setUserIdToDelete] = useState(null);
    const dropdownRef = useRef(null);
    const [accountsAndUsersFetched, setAccountsAndUsersFetched] = useState(false);

    const [activeComponent, setActiveComponent] = useState('manageUsers');

    useEffect(() => {
        if (activeComponent === 'manageUsers') {
            setAccountsAndUsersFetched(false);
            fetchUsers();
            // Set accountsAndUsersFetched to true after fetching accounts and users
            setAccountsAndUsersFetched(true);
        }

        if (dropdownOpen) {
            document.addEventListener('click', handleClickOutside);
        } else {
            document.removeEventListener('click', handleClickOutside);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, );

    const fetchUsers = async () => {
        try {
            if (!accountsAndUsersFetched) {
                const response = await axiosInstance.get('/admin/users');
                if (Array.isArray(response.data.data)) {
                    const usersData = response.data.data;
                    const usersWithAccounts = await Promise.all(
                        usersData.map(async (user) => {
                            try {
                                const accountsResponse = await axiosInstance.get(`/admin/users/getUsersAccounts/${user.id}`);
                                const accountNames = await Promise.all(
                                    accountsResponse.data.map(async (acc_id) => {
                                        const accNameResponse = await axiosInstance.get(`/admin/accounts/${acc_id}`);
                                        return accNameResponse.data.data.name;
                                    })
                                );
                                user.accounts = accountNames || [];
                            } catch (error) {
                                user.accounts = [];
                            }
                            return user;
                        })
                    );
                    const sortedUsers = usersWithAccounts.sort((a, b) => a.id - b.id);
                    setUsers(sortedUsers);
                    setAccountsAndUsersFetched(true);
                } else {
                }
            } else {
            }
        } catch (error) {
            setError('Virhe käyttäjien hakemisessa.');
            console.log(setAllAccounts)
        }
    };

    const toggleDropdown = (id) => {
        setDropdownOpen((prev) => (prev === id ? null : id));
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownOpen(null);
        }
    };

    const handleDeleteUser = (userId) => {
        setDropdownOpen(null);
        setDeleteConfirmation(true);
        setUserIdToDelete(userId);
        setAccountsAndUsersFetched(false);
    };

    const confirmDelete = async () => {
        try {
            await axiosInstance.delete(`/admin/users/${userIdToDelete}`);
            setDeleteConfirmation(false);
            setUserIdToDelete(null);
            setUpdateMessage('User deleted');
            setAccountsAndUsersFetched(false);
            fetchUsers();
        } catch (error) {
            setError('Virhe käyttäjän poistamisessa');
        }
    };

    const cancelDelete = () => {
        setDeleteConfirmation(false);
        setUserIdToDelete(null);
    };

    const handleUpdateUser = (user) => {
        setAccountsAndUsersFetched(false);
        setDropdownOpen(null);
        setUserIdToUpdate(user.id);
        setFormData({
            name: user.name,
            email: user.email,
            phone: user.phone || '',
            role: user.role || '',
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axiosInstance.patch(`/admin/users/${userIdToUpdate}`, formData);
            setUserIdToUpdate(null);
            setFormData({
                name: '',
                email: '',
                phone: '',
                role: '',
            });
            setAccountsAndUsersFetched(false);
            fetchUsers(); // Fetch users only after successful update
            setUpdateMessage('Käyttäjä päivitetty onnistuneesti');
        } catch (error) {
            setError(error.response.data.error.message);
        }
    };

    const handleCancelUpdate = () => {
        setUserIdToUpdate(null);
        setFormData({
            name: '',
            email: '',
            phone: '',
            role: '',
            account: {
                accounts: [],
            },
        });
    };

    return (
        <div className="bg-gray-900 min-h-screen">
            <div className="mx-auto max-w-7xl">
            <nav className="bg-gray-800 p-0 m-0 relative top-0 left-0">
    <ul className="flex space-x-6 ">
        <li>
            <button className="text-white hover:bg-gray-700 font-semibold" onClick={() => {setActiveComponent('manageUsers');
        fetchUsers();}}>
                Hallitse käyttäjiä
            </button>
        </li>
        <li>
            <button className="text-white hover:bg-gray-700 font-semibold" onClick={() => setActiveComponent('addUser')}>
                Lisää käyttäjä
            </button>
        </li>
    </ul>
</nav>


                {activeComponent === 'manageUsers' && (
                    <div className="bg-gray-900 py-10">
                        <div className="px-4 sm:px-6 lg:px-8">
                            <div className="sm:flex sm:items-center">
                                <div className="sm:flex-auto">
                                    <h1 className="text-base font-semibold leading-6 text-white">Hallitse Käyttäjiä</h1>
                                    {updateMessage && <p className="mt-1 text-sm text-green-500">{updateMessage}</p>}
                                    {error && <p className="mt-1 text-sm text-red-500">{error}</p>}
                                </div>
                            </div>

                            <div className="mt-8 flow-root">
                                <div className="-mx-4 -my-2  sm:-mx-6 lg:-mx-8">
                                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                        <table className="min-w-full divide-y divide-gray-700">
                                            <thead>
                                                <tr>
                                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-0">Nimi</th>
                                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">Sähköposti</th>
                                                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-white">Rooli</th>
                                                </tr>
                                            </thead>
                                            <tbody class="divide-y divide-gray-800">
                                                {users.map((user) => (
                                                    <tr key={user.id}>
                                                        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-white sm:pl-0">{user.name}</td>
                                                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-300">{user.email}</td>
                                                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-300">{user.role}</td>
                                                        
                                                        <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                                            <div className="relative" ref={dropdownRef}>
                                                                <button class="three-dots text-white" onClick={() => toggleDropdown(user.id)}>...</button>
                                                                {dropdownOpen === user.id && (
                                                                    <div className="dropdown-menu absolute z-10 bg-gray-800" onClick={(e) => e.stopPropagation()}>
                                                                        <button onClick={() => handleUpdateUser(user)} className="dropdown-item text-white">Päivitä käyttäjän tietoja</button>
                                                                        <button onClick={() => handleDeleteUser(user.id)} className="dropdown-item text-white">Poista käyttäjä</button>
                                                                        <button onClick={() => toggleDropdown(null)} className="dropdown-item text-white">Peruuta</button>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {activeComponent === 'addUser' && <AdminAddUser />}

                {userIdToUpdate && (
                    <UpdateUserForm
                        formData={formData}
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                        handleCancelUpdate={handleCancelUpdate}
                        allAccounts={allAccounts}
                    />
                )}

                {deleteConfirmation && (
                    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 h-full w-full" onClick={cancelDelete}>
                        <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white" onClick={(e) => e.stopPropagation()}>
                            <div className="mt-3 text-center">
                                <h3 class="leading-lg font-medium text-gray-900">Delete User</h3>
                                <div class="mt-2 px-7 py-3">
                                    <p class="text-sm text-gray-500">Are you sure you want to delete user "{users.find(user => user.id === userIdToDelete)?.first_name}" action cannot be undone</p>
                                </div>

                                <div class="items-center px-4 py-3">
                                    <button class="bg-red-500 text-white hover:bg-red-400 font-bold py-2 px-4 rounded" onClick={confirmDelete}>Delete User</button>
                                    <button class="bg-gray-500 text-white hover:bg-gray-400 font-bold py-2 px-4 rounded" onClick={cancelDelete} autoFocus>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default AdminManageUsers;


