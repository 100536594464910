import React from 'react';
import { Helmet } from 'react-helmet-async';
import './ProductLandingPage.css';
import ReactTypingEffect from 'react-typing-effect';
import LaravelBlogPost from './LaravelBlog';
import PythonBlogPost from './PythonBlog';
import ExpressBlogPost from './ExpressBlog';

const ProductLandingPage = ({ handleLoginSelection }) => {
  
  const handleLinkClick = (selection) => {
    handleLoginSelection(selection);
    const navbarLinks = document.querySelector('.linkki');
    if (navbarLinks) {
      navbarLinks.classList.remove('active');
    }
  };

  return (
    <div className="mb-8">
      {/* Meta Tags for SEO */}
      <Helmet>
        <title>SnappCode - Your Ultimate Backend Generator</title>
        <meta name="description" content="SnappCode is a powerful backend generator that streamlines your development process by automatically creating routes, models, and controllers. Boost your productivity and focus on what truly matters with SnappCode." />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Product",
              "name": "SnappCode",
              "description": "SnappCode is a powerful backend generator that streamlines your development process by automatically creating routes, models, and controllers.",
              "brand": {
                "@type": "Brand",
                "name": "SnappCode"
              },
              "offers": {
                "@type": "Offer",
                "url": "https://www.snappcode.eu",
                "price": "0.00",
                "priceCurrency": "EUR",
                "availability": "https://schema.org/InStock"
              }
            }
          `}
        </script>
      </Helmet>

      {/* Main Landing Page Content */}
      <div className="landing-page2">
        <div className="left-section">
          <h2 className="left-headline">Start Focusing on Interesting Code!</h2> <br />
          <div className="landing-image"></div>
          
          <h2 className="left-headline2">
            Take the boring stuff out from the scale and use your time for coding more effectively. With SnappCode you 
            will have time to generate better AI pictures than we did.
          </h2>
          <button
            type="button"
            className="linkki bg-green-600 hover:bg-green-500 text-white font-bold py-4 px-10 mt-4 rounded-lg shadow-lg transition duration-300"
            onClick={() => handleLinkClick('signUp')}
          >
            Join SnappCode!
          </button>
        </div>
        <div className="right-section">
          <h1 className="hyppyteksti main-headline">
            Let SnappCode do{' '}
            <ReactTypingEffect
              text={['the repetitive work.', 'Databases and Routes.', 'Controllers and Models.']}
              speed={40}
              eraseSpeed={50}
              eraseDelay={2000}
              typingDelay={500}
              loop
            />
          </h1>
          <p className="code-snippet">
            {'// I want to use my time better.\n'}
            {'function SnappCode() {\n'}
            {'  return($Time, $Value, $Fun, $Innovation);\n'}
            {'}'}
          </p>
          {/* <h2 className="left-headline3">
            We are at the moment testing our product live. Feel free to try it out and give us feedback in email:{' '}
            <a href="mailto:info@snappcode.eu" className="text-blue-400 hover:underline">
              info@snappcode.eu
            </a>. The testing period ends 2. of November.
          </h2> */}
          <button
            type="button"
            className="linkki bg-blue-900 hover:bg-blue-700 text-white font-bold py-4 px-10 mt-4 rounded-lg shadow-lg transition duration-300"
            onClick={() => handleLinkClick('laravel')}
          >
            Read more about Laravel
          </button>
        </div>
      </div>

      {/* Additional Content Optimized for "Backend Generator" */}
      <div className="backend-generator-section">
        <h1 className="backend-generator-title">Why Choose SnappCode as Your Backend Generator?</h1>
        <p className="backend-generator-paragraph">
          SnappCode serves as your ultimate backend generator, designed to accelerate your development workflow by automating the creation of essential backend components. Whether you're building a simple API or a complex web application, SnappCode ensures that your backend infrastructure is robust, scalable, and maintainable.
        </p>
        <ul className="backend-generator-list">
          <li className="backend-generator-list-item">
            <h2><strong>Automated Route Creation:</strong></h2>
            <p className="backend-generator-paragraph">
              SnappCode generates well-structured routes, saving you time and reducing the potential for errors.
            </p>
          </li>
          <li className="backend-generator-list-item">
          <h2><strong>Efficient Model Generation:</strong></h2>
            <p className="backend-generator-paragraph">
              Create database models effortlessly with SnappCode, ensuring consistency and adherence to best practices.
            </p>
          </li>
          <li className="backend-generator-list-item">
          <h2> <strong>Streamlined Controller Setup:</strong></h2>
            <p className="backend-generator-paragraph">
              SnappCode sets up controllers with predefined methods, allowing you to focus on implementing business logic.
            </p>
          </li>
          <li className="backend-generator-list-item">
          <h2> <strong>Customization and Flexibility:</strong></h2>
            <p className="backend-generator-paragraph">
              Tailor the generated code to fit your specific project requirements, enhancing flexibility without sacrificing speed.
            </p>
          </li>
        </ul>
        <button
          type="button"
          className="linkki bg-green-600 hover:bg-green-500 text-white font-bold py-4 px-10 mt-4 rounded-lg shadow-lg transition duration-300"
          onClick={() => handleLinkClick('signUp')}
        >
          Get Started with SnappCode
        </button>
      </div>
    </div>
  );
};

export default ProductLandingPage;
