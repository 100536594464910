import React from 'react';
import './Affiliate.css';

const AffiliateLandingPage = ({ handleLoginSelection }) => {
  const handleLinkClick = (selection) => {
    handleLoginSelection(selection);
    const navbarLinks = document.querySelector('.linkki2');
    navbarLinks.classList.remove('active');
  };

  return (
    <div className="landing-page3">
      <div className="centered-content">
        <h2 className="main-headline centered-headline">
          Unlock Easy Earnings: How Selling a Great Product with Coupons Can Boost Your Online Income
        </h2>
        <p className="blog-content centered-text">
          In today’s digital age, the allure of making money online has never been stronger. Whether you're a seasoned affiliate marketer or just starting your journey to financial freedom, finding the right strategy is crucial. Imagine a simple yet effective way to earn while promoting a product you believe in. Welcome to our innovative coupon-based referral system—where selling a quality product translates into real earnings with minimal effort.
        </p>
      </div>

      <div className="landing-image2"></div>
      <button
          type="submit"
          className="linkki2 bg-yellow-600 hover:bg-yellow-500 text-white font-bold py-4 px-10 mt-4 rounded-lg shadow-lg transition duration-300 centered-button"
          onClick={() => handleLinkClick('signUp')}
        >
          Sign Up Now
        </button>

      <div className="centered-content">
        <div className=''>
        <h3 className="blog-subtitle centered-headline">Why Affiliate Marketing is a Powerful Tool for Making Money Online</h3><br></br>
        <p className="blog-content centered-text">
          Affiliate marketing has revolutionized the way individuals generate income online. By promoting products or services, affiliates earn commissions for every sale made through their unique referral links. This model offers flexibility, scalability, and the potential for passive income. However, the key to success lies in partnering with products that offer genuine value to consumers.
        </p>
        </div>
        <div className='bg-green-700'>
        <h3 className="blog-subtitle centered-headline">Introducing Our Coupon-Based Referral System</h3><br></br>
        <p className="blog-content centered-text">
          Our platform takes affiliate marketing to the next level by integrating a unique coupon system:<br></br><br></br>
          <ul className="centered-list">
            <li><strong>Sign Up and Get Your Unique Coupon:</strong> Join and receive a personalized coupon code that offers discounts to new customers.</li>
            <li><strong>Share and Promote:</strong> Distribute your coupon through social media, blogs, email campaigns, or any preferred channels.</li>
            <li><strong>Earn Rewards:</strong> For every new user who purchases our product using your coupon, you earn a commission. The more you share, the more you earn!</li>
          </ul>
        </p>
        </div>
      
        <h3 className="blog-subtitle centered-headline">Start Earning Today: Join Our Affiliate Program</h3><br></br>
        <p className="blog-content centered-text">
          Don’t miss out on the opportunity to make money online with a proven, easy-to-use system. By promoting a quality product and leveraging our coupon-based referral program, you can achieve your financial goals with minimal hassle.
        </p>
        <button
          type="submit"
          className="linkki2 bg-yellow-600 hover:bg-yellow-500 text-white font-bold py-4 px-10 mt-4 rounded-lg shadow-lg transition duration-300 centered-button"
          onClick={() => handleLinkClick('signUp')}
        >
          Sign Up Now
        </button>
      </div>
    </div>
  );
};

export default AffiliateLandingPage;