import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import TableBuilder from './TableBuilder';
import axiosInstance from '../../axios';

const LaravelMigrate = () => {
    const [tables, setTables] = useState([
        { id: 1, name: 'accounts', fields: [{ id: 1, name: 'name', type: 'string', nullable: false, default: '', fillable: true, cast: false }], timestamps: true },
        { id: 2, name: 'users', fields: [
            { id: 2, name: 'role', type: 'string', nullable: false, default: '', fillable: true, cast: false },
            { id: 3, name: 'first_name', type: 'string', nullable: false, default: '', fillable: true, cast: false },
            { id: 4, name: 'last_name', type: 'string', nullable: false, default: '', fillable: true, cast: false }
        ], timestamps: true }
    ]);
    const [notification, setNotification] = useState({ message: '', type: '' });

    // Adds a new table with default values
    const addTable = () => {
        const newId = tables.length > 0 ? tables[tables.length - 1].id + 1 : 1;
        const newTable = { id: newId, name: '', fields: [], timestamps: true };
        setTables([...tables, newTable]);
    };

    // Removes a table based on its index
    const removeTable = (index) => {
        const updatedTables = tables.filter((_, i) => i !== index);
        setTables(updatedTables.map((table, idx) => ({ ...table, id: idx + 1 })));
    };

    // Updates a table's data based on its index
    const updateTable = (index, newTable) => {
        const updatedTables = [...tables];
        updatedTables[index] = newTable;
        setTables(updatedTables);
    };

    // Handles drag-and-drop reordering
    const onDragEnd = (result) => {
        if (!result.destination) return;

        const reorderedTables = Array.from(tables);
        const [movedTable] = reorderedTables.splice(result.source.index, 1);
        reorderedTables.splice(result.destination.index, 0, movedTable);

        setTables(reorderedTables.map((table, idx) => ({ ...table, id: idx + 1 })));
    };

    // Generates the migration PHP code with fixes
    const generateMigration = () => {
        let migration = `<?php\n\nuse Illuminate\\Database\\Migrations\\Migration;\nuse Illuminate\\Database\\Schema\\Blueprint;\nuse Illuminate\\Support\\Facades\\Schema;\n\nreturn new class extends Migration\n{\n`;
        migration += `    public function up(): void\n    {\n`;

        tables.forEach(table => {
            migration += `        Schema::create('${table.name}', function (Blueprint $table) {\n`;

            // Automatically add 'id' field if not present
            const hasIdField = table.fields.some(field => field.type === 'id' && field.name === 'id');
            if (!hasIdField) {
                migration += `            $table->id();\n`;
            }

            table.fields.forEach(field => {
                if (field.type === 'foreignId' && field.references) {
                    // Determine the foreign field name
                    let foreignFieldName = field.name;
                    if (!foreignFieldName) {
                        // Generate field name based on referenced table (e.g., 'accounts' -> 'account_id')
                        const singularReference = field.references.endsWith('s') ? field.references.slice(0, -1) : field.references;
                        foreignFieldName = `${singularReference}_id`;
                    }

                    // Ensure 'onDelete' is provided; if not, set a default (e.g., 'cascade')
                    const onDeleteAction = field.onDelete ? field.onDelete : 'cascade';

                    // Add the foreignId with constraints
                    migration += `            $table->foreignId('${foreignFieldName}')->constrained('${field.references}')->onDelete('${onDeleteAction}');\n`;
                }
                else if (field.type === 'enum' && field.options && field.options.length > 0) {
                    migration += `            $table->enum('${field.name}', [${field.options.map(opt => `'${opt}'`).join(', ')}]);\n`;
                }
                else {
                    // Regular field processing
                    let fieldCode = `            $table->${field.type}('${field.name}')`;
                    if (field.nullable) fieldCode += '->nullable()';
                    if (field.unique) fieldCode += '->unique()';
                    if (field.default) fieldCode += `->default('${field.default}')`;
                    // Remove ->cast() from migration as casting is handled in the model
                    migration += fieldCode + ';\n';
                }
            });

            if (table.timestamps) {
                migration += `            $table->timestamps();\n`;
            }
            migration += `        });\n\n`;
        });

        migration += `    }\n\n`;
        migration += `    public function down(): void\n    {\n`;

        // Drop tables in reverse order to handle foreign key dependencies
        tables.slice().reverse().forEach(table => {
            migration += `        Schema::dropIfExists('${table.name}');\n`;
        });

        migration += `    }\n};\n`;

        return migration;
    };

    // Deletes generated files via API call
    const deleteGenerations = () => {
        axiosInstance.get('/user/laravelDeleteFiles')
            .then(() => {
                // console.log('Files deleted successfully');
                setNotification({ message: 'Files deleted successfully', type: 'success' });
            })
            .catch(error => {
                const errorMsg = error.response?.data?.message || 'An unexpected error occurred while deleting the files.';
                console.error('Error deleting the files', errorMsg);
                setNotification({ message: errorMsg, type: 'error' });
            });

        // Clear notification after 3 seconds
        setTimeout(() => {
            setNotification({ message: '', type: '' });
        }, 3000);
    };

    // Sends migration and table data to the backend
    const sendToBackend = () => {
        deleteGenerations();
        const migration = generateMigration();

        axiosInstance.post('/user/laraveLmigration', { migration })
            .then(response => {
                // console.log('Migration sent successfully:', response.data);
                setNotification({ message: 'Migration sent successfully', type: 'success' });
            })
            .catch(error => {
                const errorMsg = error.response?.data?.message || 'An unexpected error occurred.';
                console.error('There was an error!', errorMsg);
                setNotification({ message: errorMsg, type: 'error' });
            });

        // Prepare tables with validated fields, including 'castType'
        const preparedTables = tables.map(table => ({
            ...table,
            fields: table.fields.map(field => ({
                ...field,
                nullable: field.nullable ?? false,
                unique: field.unique ?? false,
                type: field.type || 'string',
                fillable: field.fillable ?? true,
                cast: field.cast ?? false,
                castType: field.castType || '', // Include castType
                name: field.name || (field.type === 'foreignId' && field.references ? `${field.references.slice(0, -1)}_id` : 'unnamed_field'),
            }))
        }));

        axiosInstance.post('/user/laraveLgenerate', { tables: preparedTables })
            .then(response => {
              //   console.log('Tables sent successfully:', response.data.message);
                setNotification({ message: 'Tables sent successfully', type: 'success' });
            })
            .catch(error => {
                const errorMsg = error.response?.data?.message || 'An unexpected error occurred.';
                console.error('There was an error!', errorMsg);
                setNotification({ message: errorMsg, type: 'error' });
            });

        // Clear notification after 3 seconds
        setTimeout(() => {
            setNotification({ message: '', type: '' });
        }, 3000);
    };

    // Downloads the generated ZIP file via API call
    const generateZip = () => {
        axiosInstance.get('/user/laravelGenerateZip', {
            responseType: 'blob'
        })
        .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'generated_files.zip');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setNotification({ message: 'Download started successfully', type: 'success' });
        })
        .catch(error => {
            const errorMsg = error.response?.data?.message || 'Nothing to download or an error occurred.';
            console.error('Error downloading the files', errorMsg);
            setNotification({ message: errorMsg, type: 'error' });
        });

        // Clear notification after 3 seconds
        setTimeout(() => {
            setNotification({ message: '', type: '' });
        }, 3000);
    };

    return (
        <div className='container-migration bg-gray-900 p-6 rounded-lg shadow-lg max-w-4xl mx-auto'>
            {/* Drag and Drop Context */}
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="tables">
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef} className='space-y-4'>
                            {/* Add New Table Button */}
                            <div className='flex justify-end mb-4'>
                                <button
                                    className='bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600'
                                    onClick={addTable}
                                >
                                    Add New Table
                                </button>
                            </div>

                            {/* Draggable Tables */}
                            {tables.map((table, index) => (
                                <Draggable key={table.id} draggableId={String(table.id)} index={index}>
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <TableBuilder
                                                index={index}
                                                table={table}
                                                updateTable={updateTable}
                                                removeTable={removeTable}
                                                tables={tables}
                                            />
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                            <button
                                    className='bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600'
                                    onClick={addTable}
                                >
                                    Add New Table
                                </button>
                        </div>
                    )}
                    
                </Droppable>
            </DragDropContext>

            {/* Notification Message */}
            {notification.message && (
                <div
                    className={`mb-4 p-4 rounded-md ${
                        notification.type === 'success' ? 'bg-green-500' : 'bg-red-500'
                    } text-white`}
                >
                    {notification.message}
                </div>
            )}

            {/* Action Buttons */}
            <div className='flex flex-wrap space-x-2 mt-8'>
                <button
                    className='bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600'
                    onClick={sendToBackend}
                >
                    Generate Code
                </button>
                <button
                    className='bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600'
                    onClick={generateZip}
                >
                    Download Code
                </button>
            </div>

            {/* Spacer for layout */}
            <div className='mt-8'></div>
        </div>
    );
};

export default LaravelMigrate;
