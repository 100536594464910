import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import axiosInstance from '../axios';
import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded';

// Custom styles using MUI's `styled` utility
const StyledContainer = styled(Container)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(3),
  boxShadow: theme.shadows[5],
}));

const StyledBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(8),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  margin: theme.spacing(1),
  backgroundColor: theme.palette.secondary.main,
}));

// Copyright component
function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="">
        SnapCode
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const ResetPassword = ({ handleLoginSelection = () => {} }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
  });

  const handleLinkClick = (selection) => {
    handleLoginSelection(selection);
  };

  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');
  
    // Optional: Simple client-side validation
    if (!formData.email) {
      setError('Email is required.');
      return;
    }
  
    try {
      const response = await axiosInstance.post('resetPassword', { email: formData.email });
      setSuccessMessage('Password reset link sent to your email successfully.');
    } catch (error) {
      // Checking for various error responses
      if (error.response && error.response.data) {
        if (error.response.data.message) {
          // Laravel usually returns 'message' for errors
          setError(error.response.data.message);
        } else if (error.response.data.error && error.response.data.error.message) {
          // Custom error response with 'error' object
          setError(error.response.data.error.message);
        } else {
          setError('An error occurred. Please try again.');
        }
      } else {
        setError('An error occurred. Please try again.');
      }
    }
  };
  

  return (
    <div className='tausssta'>
      <div className='adminlogin'>
        <StyledContainer component="main" maxWidth="xs">
          <CssBaseline />
          <StyledBox>
            <StyledAvatar>
              <EditNoteRoundedIcon />
            </StyledAvatar>
            <Typography component="h1" variant="h5">
              Reset password
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={formData.email}
                onChange={handleChange}
                sx={{
                  backgroundColor: 'white', // Override global background
                  borderRadius: 1,
                }}
                error={!!error}
              />
  
              {error && <Typography color="error" sx={{ mt: 2 }}>{error}</Typography>}
              {successMessage && <Typography color="primary" sx={{ mt: 2 }}>{successMessage}</Typography>}
  
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Send new password
              </Button>
              <Grid container>
                <Grid item>
                  <Link href="#" variant="body2">
                    <a onClick={() => handleLinkClick('user')}>Back to Sign in</a>
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </StyledBox>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </StyledContainer>
      </div>
    </div>
  );
}

export default ResetPassword;
