import React, { useState, useEffect, useRef } from 'react';
import axiosInstance from '../../axios';
import UpdateProductForm from './adminUpdateProductForm';
import AdminAddProduct from './adminAddProducts';

function AdminManageProducts() {
    const [products, setProducts] = useState([]);
    const [error, setError] = useState('');
    const [productIdToUpdate, setProductIdToUpdate] = useState(null);
    const [dropdownOpen, setDropdownOpen] = useState(null);
    const [formData, setFormData] = useState({
        role: '',
        name: '',
        price: '',
        currency: '',
        description: '',
        features: '',
        popular: false,
    });
    const [updateMessage, setUpdateMessage] = useState('');
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);
    const [productIdToDelete, setProductIdToDelete] = useState(null);
    const dropdownRef = useRef(null);
    const [activeComponent, setActiveComponent] = useState('manageProducts');

    useEffect(() => {
        if (activeComponent === 'manageProducts') {
            fetchProducts();
        }

        if (dropdownOpen) {
            document.addEventListener('click', handleClickOutside);
        } else {
            document.removeEventListener('click', handleClickOutside);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [activeComponent, dropdownOpen]);

    const fetchProducts = async () => {
        try {
            const response = await axiosInstance.get('/admin/products');
            setProducts(response.data);
        } catch (error) {
            setError('Virhe tuotteiden hakemisessa.');
            console.log(error);
        }
    };

    const toggleDropdown = (id) => {
        setDropdownOpen((prev) => (prev === id ? null : id));
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownOpen(null);
        }
    };

    const handleDeleteProduct = (productId) => {
        setDropdownOpen(null);
        setDeleteConfirmation(true);
        setProductIdToDelete(productId);
    };

    const confirmDelete = async () => {
        try {
            await axiosInstance.delete(`/admin/products/${productIdToDelete}`);
            setDeleteConfirmation(false);
            setProductIdToDelete(null);
            setUpdateMessage('Product deleted');
            fetchProducts();
        } catch (error) {
            setError('Virhe tuotteen poistamisessa');
        }
    };

    const cancelDelete = () => {
        setDeleteConfirmation(false);
        setProductIdToDelete(null);
    };

    const handleUpdateProduct = (product) => {
        setDropdownOpen(null);
        setProductIdToUpdate(product.id);
        setFormData({
            role: product.role,
            name: product.name,
            price: product.price,
            currency: product.currency,
            description: product.description,
            features: Array.isArray(product.features) ? product.features.join(',') : JSON.parse(product.features).join(','),
            popular: product.popular,
        });
    };
    

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: checked,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let payload = { ...formData, features: formData.features.split(',') };
            await axiosInstance.put(`/admin/products/${productIdToUpdate}`, payload);
            setProductIdToUpdate(null);
            setFormData({
                role: '',
                name: '',
                price: '',
                currency: '',
                description: '',
                features: '',
                popular: false,
            });
            fetchProducts(); // Fetch products only after successful update
            setUpdateMessage('Tuote päivitetty onnistuneesti');
        } catch (error) {
            setError('Virhe tuotteen päivittämisessä.');
        }
    };

    const handleCancelUpdate = () => {
        setProductIdToUpdate(null);
        setFormData({
            role: '',
            name: '',
            price: '',
            currency: '',
            description: '',
            features: '',
            popular: false,
        });
    };

    return (
        <div className="bg-gray-900 min-h-screen">
            <div className="mx-auto max-w-7xl">
                <nav className="bg-gray-800 p-0 m-0 relative top-0 left-0">
                    <ul className="flex space-x-6 ">
                        <li>
                            <button className="text-white hover:bg-gray-700 font-semibold" onClick={() => { setActiveComponent('manageProducts'); fetchProducts(); }}>
                                Hallitse tuotteita
                            </button>
                        </li>
                        <li>
                            <button className="text-white hover:bg-gray-700 font-semibold" onClick={() => setActiveComponent('addProduct')}>
                                Lisää tuote
                            </button>
                        </li>
                    </ul>
                </nav>

                {activeComponent === 'manageProducts' && (
                    <div className="bg-gray-900 py-10">
                        <div className="px-4 sm:px-6 lg:px-8">
                            <div className="sm:flex sm:items-center">
                                <div className="sm:flex-auto">
                                    <h1 className="text-base font-semibold leading-6 text-white">Hallitse Tuotteita</h1>
                                    {updateMessage && <p className="mt-1 text-sm text-green-500">{updateMessage}</p>}
                                    {error && <p className="mt-1 text-sm text-red-500">{error}</p>}
                                </div>
                            </div>

                            <div className="mt-8 flow-root">
                                <div className="-mx-4 -my-2  sm:-mx-6 lg:-mx-8">
                                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                        <table className="min-w-full divide-y divide-gray-700">
                                            <thead>
                                                <tr>
                                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-0">Nimi</th>
                                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">Hinta</th>
                                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">Rooli</th>
                                                </tr>
                                            </thead>
                                            <tbody className="divide-y divide-gray-800">
                                                {products.map((product) => (
                                                    <tr key={product.id}>
                                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-white sm:pl-0">{product.name}</td>
                                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">{product.price} {product.currency}</td>
                                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">{product.role}</td>
                                                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                                            <div className="relative" ref={dropdownRef}>
                                                                <button className="three-dots text-white" onClick={() => toggleDropdown(product.id)}>...</button>
                                                                {dropdownOpen === product.id && (
                                                                    <div className="dropdown-menu absolute z-10 bg-gray-800" onClick={(e) => e.stopPropagation()}>
                                                                        <button onClick={() => handleUpdateProduct(product)} className="dropdown-item text-white">Päivitä tuotteen tiedot</button>
                                                                        <button onClick={() => handleDeleteProduct(product.id)} className="dropdown-item text-white">Poista tuote</button>
                                                                        <button onClick={() => toggleDropdown(null)} className="dropdown-item text-white">Peruuta</button>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {activeComponent === 'addProduct' && <AdminAddProduct />}

                {productIdToUpdate && (
                    <UpdateProductForm
                        formData={formData}
                        handleChange={handleChange}
                        handleCheckboxChange={handleCheckboxChange}
                        handleSubmit={handleSubmit}
                        handleCancelUpdate={handleCancelUpdate}
                    />
                )}

                {deleteConfirmation && (
                    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 h-full w-full" onClick={cancelDelete}>
                        <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white" onClick={(e) => e.stopPropagation()}>
                            <div className="mt-3 text-center">
                                <h3 className="leading-lg font-medium text-gray-900">Poista Tuote</h3>
                                <div className="mt-2 px-7 py-3">
                                    <p className="text-sm text-gray-500">Oletko varma, että haluat poistaa tuotteen "{products.find(product => product.id === productIdToDelete)?.name}"? Tätä toimintoa ei voi peruuttaa.</p>
                                </div>

                                <div className="items-center px-4 py-3">
                                    <button className="bg-red-500 text-white hover:bg-red-400 font-bold py-2 px-4 rounded" onClick={confirmDelete}>Poista Tuote</button>
                                    <button className="bg-gray-500 text-white hover:bg-gray-400 font-bold py-2 px-4 rounded" onClick={cancelDelete} autoFocus>Peruuta</button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default AdminManageProducts;