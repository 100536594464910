import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axios';

const ControllerEditor = () => {
    const [content, setContent] = useState('');
    const [loading, setLoading] = useState(true);
    const [notification, setNotification] = useState({ message: '', type: '' });

    useEffect(() => {
        axiosInstance.get('/user/nodEcontrollerFile')
            .then(response => {
                setContent(response.data.content);
                setLoading(false);
            })
            .catch(error => {
                console.error(error);
                setLoading(false);
            });
    }, []);

    const handleSave = () => {
        axiosInstance.post('/user/nodEcontrollerFile', { content })
            .then(response => {
                setNotification({ message: 'File saved successfully', type: 'success' });
            })
            .catch(error => {
                setNotification({ message: 'Error saving file. Please try again.', type: 'error' });
                console.error(error);
            });

        setTimeout(() => {
            setNotification({ message: '', type: '' });
        }, 3000);
    };

    const handleDefaultFile = () => {
        axiosInstance.post('/user/nodEcopyDefaultControllerFile', { content })
            .then(response => {
                setNotification({ message: 'File modified back to default successfully', type: 'success' });
                return axiosInstance.get('/user/nodEcontrollerFile');
            })
            .then(response => {
                setContent(response.data.content);
                setLoading(false);
            })
            .catch(error => {
                setNotification({ message: 'Error resetting file to default. Please try again.', type: 'error' });
                console.error(error);
                setLoading(false);
            });

        setTimeout(() => {
            setNotification({ message: '', type: '' });
        }, 3000);
    };

    return (
        <div className='kotipituus'>
            <div className='editorContent'>
                <h3>Modify your controller template</h3>
                {notification.message && (
                    <div
                        className={`mb-4 p-4 rounded-md ${
                            notification.type === 'success' ? 'bg-green-500' : 'bg-red-500'
                        } text-white`}
                    >
                        {notification.message}
                    </div>
                )}
                <textarea
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                    rows="15"
                    cols="80"
                ></textarea>
                <br />
                <button onClick={handleSave}>Save</button>
                <button onClick={handleDefaultFile}>Set default controller template</button>
            </div>
        </div>
    );
};

export default ControllerEditor;
