import React from 'react';
import FieldBuilder from './FieldBuilder';

const TableBuilder = ({ index, table, updateTable, removeTable, tables }) => {
    const updateTableName = (e) => {
        const newTable = { ...table, name: e.target.value };
        updateTable(index, newTable);
    };

    const updateTimestamps = (e) => {
        const newTable = { ...table, timestamps: e.target.checked }; // Sequelize automatically handles createdAt and updatedAt with timestamps: true
        updateTable(index, newTable);
    };

    const addField = () => {
        // Adding a new field with default Sequelize field values
        const newField = { name: '', type: 'STRING', allowNull: true, unique: false, defaultValue: '' };
        const newTable = { ...table, fields: [...table.fields, newField] };
        updateTable(index, newTable);
    };

    const addForeignKey = () => {
        // Adding a foreign key field with reference handling
        const newField = { name: '', type: 'INTEGER', reference: '', onDelete: 'CASCADE', allowNull: false, unique: false };
        const newTable = { ...table, fields: [...table.fields, newField] };
        updateTable(index, newTable);
    };

    const removeField = (fieldIndex) => {
        const newFields = table.fields.filter((_, i) => i !== fieldIndex);
        const newTable = { ...table, fields: newFields };
        updateTable(index, newTable);
    };

    const updateField = (fieldIndex, newField) => {
        const newFields = [...table.fields];
        newFields[fieldIndex] = newField;
        const newTable = { ...table, fields: newFields };
        updateTable(index, newTable);
    };

    return (
        <div className='table-container p-6 mb-6 border rounded-md bg-gray-900'>
            <div className='flex justify-between items-center mb-4'>
                <div className='w-full'>
                    <label className='block text-sm font-medium text-gray-300'>Table Name</label>
                    <input
                        className='w-full max-w-full sm:max-w-xs p-2 rounded-md bg-gray-700 text-black border border-gray-600'
                        type="text"
                        value={table.name}
                        onChange={updateTableName}
                        placeholder="Table Name"
                    />
                </div>
                <button
                    type="button"
                    className='ml-4 bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600'
                    onClick={() => removeTable(index)}
                    aria-label="Remove Table"
                >
                    Remove Table
                </button>
            </div>

            {/* Fields */}
            <div className='mb-4'>
                <h3 className='text-lg font-semibold text-gray-300'>Fields for {table.name || 'Table'}:</h3>
                {table.fields.length === 0 && (
                    <p className='text-gray-500'>No fields added yet. Click "Add Field" to start.</p>
                )}
                <div className='tableflex flex-wrap space-y-4 mt-2'>
                    {table.fields.map((field, fieldIndex) => (
                        <FieldBuilder
                            key={fieldIndex}
                            field={field}
                            updateField={(newField) => updateField(fieldIndex, newField)}
                            removeField={() => removeField(fieldIndex)}
                            tables={tables.filter(t => t.id < table.id)}
                        />
                    ))}
                </div>
            </div>

            {/* Additional Table Options */}
            <div className='mb-4 grid grid-cols-1 md:grid-cols-2 gap-4'>
                {/* Timestamps */}
                <div className='flex items-center'>
                    <input
                        id={`timestamps-${index}`}
                        name="timestamps"
                        type="checkbox"
                        checked={table.timestamps || false}
                        onChange={updateTimestamps}
                        className='h-4 w-4 text-blue-600 border-gray-300 rounded'
                    />
                    <label htmlFor={`timestamps-${index}`} className='ml-2 block text-sm text-gray-300'>
                        Timestamps
                    </label>
                </div>
            </div>

            {/* Table Actions */}
            <div className='flex space-x-4 mt-4'>
                <button
                    type="button"
                    className='bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600'
                    onClick={addField}
                >
                    Add Field
                </button>
                <button
                    type="button"
                    className='bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600'
                    onClick={addForeignKey}
                >
                    Add Foreign Key
                </button>
                <button
                    type="button"
                    className='bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600'
                    onClick={() => removeTable(index)}
                >
                    Remove Table
                </button>
            </div>
        </div>
    );
};

export default TableBuilder;