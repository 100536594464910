import React from 'react';

function UpdateUserForm({ formData, handleChange, handleSubmit, handleCancelUpdate }) {
    return (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-900 bg-opacity-90 z-50">
            <div className="bg-gray-800 p-4 w-full max-w-lg rounded-lg border border-gray-700">
                <h3 className="text-lg font-semibold mb-4 text-white">Update User</h3>
                <form onSubmit={handleSubmit}>
                    {['name', 'email', 'registeration_coupon', 'affiliate_coupon'].map(field => (
                        <div key={field} className="flex items-center mb-2">
                            <label className="text-white w-32 mr-4 capitalize">{field.replace('_', ' ')}:</label>
                            <input
                                type={field === 'email' ? 'email' : 'text'}
                                name={field}
                                value={formData[field]}
                                onChange={handleChange}
                                className="block w-full rounded-md border-0 bg-gray-700 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-600 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                            />
                        </div>
                    ))}

                    <div className="flex items-center mb-2">
                        <label className="text-white w-32 mr-4">License Ends:</label>
                        <input
                            type="date"
                            name="license_ends"
                            value={formData.license_ends}
                            onChange={handleChange}
                            className="block w-full rounded-md border-0 bg-gray-700 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-600 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                        />
                    </div>

                    <div className="flex items-center mb-2">
                        <label className="text-white w-32 mr-4">Generations:</label>
                        <input
                            type="number"
                            name="generations"
                            value={formData.generations}
                            onChange={handleChange}
                            className="block w-full rounded-md border-0 bg-gray-700 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-600 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                        />
                    </div>

                    <div className="flex items-center mb-4">
                        <label className="text-white w-32 mr-4">Role:</label>
                        <select
                            name="role"
                            value={formData.role}
                            onChange={handleChange}
                            className="block w-full rounded-md border-0 bg-gray-700 py-1.5 text-white shadow-sm ring-1 ring-inset ring-gray-600 focus:ring-2 focus:ring-inset focus:ring-blue-500"
                        >
                            <option className="text-white" value="user">User</option>
                            <option className="text-white" value="admin">Admin</option>
                            <option className="text-white" value="laravel">Laravel</option>
                            <option className="text-white" value="node">Node</option>
                            <option className="text-white" value="python">Python</option>
                            <option className="text-white" value="react">React</option>
                            <option className="text-white" value="back">Back</option>
                            <option className="text-white" value="front">Front</option>
                            <option className="text-white" value="full">Full</option>
                            <option className="text-white" value="affiliate">Affiliate</option>
                            <option className="text-white" value="free">Free</option>
                        </select>
                    </div>

                    {/* Password Input Fields */}
                    <div className="flex items-center mb-2">
                        <label className="text-white w-32 mr-4">Password:</label>
                        <input
                            type="password"
                            name="password"
                            value={formData.password || ''}  // Initialize empty string if not present
                            onChange={handleChange}
                            className="block w-full rounded-md border-0 bg-gray-700 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-600 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                        />
                    </div>

                    <div className="flex items-center mb-4">
                        <label className="text-white w-32 mr-4">Confirm Password:</label>
                        <input
                            type="password"
                            name="confirm_password"
                            value={formData.confirm_password || ''}  // Initialize empty string if not present
                            onChange={handleChange}
                            className="block w-full rounded-md border-0 bg-gray-700 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-600 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                        />
                    </div>

                    <div className="flex justify-between">
                        <button type="submit" className="rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500">Update</button>
                        <button type="button" onClick={handleCancelUpdate} className="text-sm font-semibold leading-6 text-white">Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default UpdateUserForm;
