import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

const stripePromise = loadStripe('pk_test_51Pkvq0BI4wVooulxwLLFZdQkoh5qdPYnAz83uwjp7NvPghLHobk6I4q7msjYHL66vPnkzQzRlaEVWJjqJWg045UH00ov08Pnmh');

const StripeProvider = ({ children }) => (
    <Elements stripe={stripePromise}>
        {children}
    </Elements>
);

export default StripeProvider;
