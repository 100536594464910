import React from 'react';
import StripeProvider from '../../Stripe/StripeProvider';
import PaymentForm from '../../Stripe/StripePaymentForm';

const PaymentComponent = ({ product }) => {
    return (
        <div>
            <StripeProvider>
                <h2>Stripe Payment</h2>
                <PaymentForm product={product} />
            </StripeProvider>
        </div>
    );
};

export default PaymentComponent;
