import React, { useState } from 'react';
import './LandingPage.css';
import PaymentComponent from './PaymentComponent';
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axiosInstance from '../axios';
import ProductLandingPage from './ProductLandingPage';
import SignUp from '../login/MuiRegister';

const LandingPage = ({ handleLoginSelection }) => {
  const [activeComponent, setActiveComponent] = useState('');
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    axiosInstance.post('/join-waitlist', { email })
      .then((response) => {
        setMessage(response.data.message);
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.message) {
          setMessage(error.response.data.message);
        } else {
          setMessage('Something went wrong, please try again later.');
        }
      });
  };

  const handleLinkClick = (selection) => {
    handleLoginSelection(selection);
    const navbarLinks = document.querySelector('.linkki');
    if (navbarLinks) {
      navbarLinks.classList.remove('active');
    }
  };
  
  const handleBackClick = () => {
    setActiveComponent('');
    setSelectedProduct(null);
  };

  const lightTheme = createTheme({ palette: { mode: 'light' } });

  return (
    <div className="landing-page">
       <ProductLandingPage handleLoginSelection={handleLoginSelection} />
      {activeComponent !== 'PaymentComponent' ? (
        <>
          <ThemeProvider theme={lightTheme}>
            <Paper
              elevation={12}
              sx={{
                p: 2,
                mb: 2,
                borderRadius: 4,
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                maxWidth: '1200px',
                margin: '0 auto',
              }}
            >
              <section className="hero-section flex flex-col justify-center items-center p-8" id="Hero" style={{ backgroundSize: 'cover', backgroundPosition: 'center', minHeight: '50vh' }}>
                <div className="hero-content text-center">
                  <h1 className="text-4xl md:text-5xl font-bold text-white mb-4">Transform Your Idea into Reality</h1>
                  <p className="text-lg md:text-xl text-gray-200 mb-8 max-w-2xl">Join SnappCode waitlist. We will let you know when the app is live. You will also receive coupon for cheaper price for the release!</p>
                  <form onSubmit={handleSubmit} className="flex flex-col items-center space-y-4 w-full max-w-md">
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter your email"
                      required
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg text-center focus:outline-none focus:ring-2 focus:ring-blue-500 text-black"
                    />
                    <button
                      type="submit"
                      className="bg-red-600 hover:bg-red-500 text-white font-bold py-4 px-10 rounded-lg shadow-lg transition duration-300"
                    >
                      Join Waitlist
                    </button>
                  </form>
                  {message && <p className="text-center mt-4 text-white">{message}</p>}
                </div>
              </section>
              <section className="features-section grid grid-cols-1 md:grid-cols-2 gap-8 p-4">
                <div className="feature-box text-center">
                  <h2 className="text-3xl font-bold text-gray-800 mb-4">Generate Backends in Lightning Speed</h2>
                  <p className="text-gray-600">Our platform takes care of generating most of the important backend files, so you can focus on making your product unique.</p>
                </div>
                <div className="feature-box text-center">
                  <h2 className="text-3xl font-bold text-gray-800 mb-4">Focus on Innovation</h2>
                  <p className="text-gray-600">SnappCode helps you bring your ideas to life by taking away the hassle of copypasting and modifying files manually</p>
                </div>
              </section>
            </Paper><br></br><br></br>
          </ThemeProvider>
        </>
      ) : (
      /*   <div>
          <button onClick={handleBackClick}>Back</button>
          <PaymentComponent product={selectedProduct} />
        </div> */
        <div><br></br>
        </div>
      )}
      {/* Pass handleLoginSelection down as a prop */}
     
    </div>
  );
};

export default LandingPage;
