import React, { useState, useEffect, useRef } from 'react';
import NavbarForm from './NavBarForm';
import TableForm from './TableForm';
import FormForm from './FormForm';
import LoginForm from './LoginForm';
import { Disclosure } from '@headlessui/react';

function ReactMainComponent() {
    const [activeComponent, setActiveComponent] = useState('ReactMainComponent');

    const handleViewToggle = (view, close) => {
        setActiveComponent(view);
        close();
    };

    return (
        <div className="bg-gray-900 w-full min-h-screen">
            <div className="mx-auto max-w-7xl w-full bg-gray-900">
                <Disclosure as="nav">
                    {({ open, close }) => (
                        <>
                            <div className="bg-gray-800 p-0 m-0 relative top-0 left-0 w-full">
                                <div className="flex items-center justify-between p-4">
                                    <Disclosure.Button className="inline-flex items-center rounded-md bg-gray-900 px-3 py-2 text-sm font-medium text-white hover:bg-gray-700 focus:outline-none lg:hidden">
                                        React Menu
                                    </Disclosure.Button>
                                    <div className="hidden lg:flex space-x-6">
                                        <button className="text-white hover:bg-gray-700 font-semibold w-full" onClick={() => handleViewToggle('ReactMainComponent', close)}>
                                            React Home
                                        </button>
                                        <button className="text-white hover:bg-gray-700 font-semibold w-full" onClick={() => handleViewToggle('table', close)}>
                                            Generate Table
                                        </button>
                                        <button className="text-white hover:bg-gray-700 font-semibold w-full" onClick={() => handleViewToggle('navbar', close)}>
                                            Generate Navbar
                                        </button>
                                        <button className="text-white hover:bg-gray-700 font-semibold w-full" onClick={() => handleViewToggle('form', close)}>
                                            Generate Form
                                        </button>
                                        <button className="text-white hover:bg-gray-700 font-semibold w-full" onClick={() => handleViewToggle('login', close)}>
                                            Generate Login
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <Disclosure.Panel className="lg:hidden">
                                <div className="space-y-1 px-2 pb-3 pt-2">
                                    <button className="block w-full text-left px-3 py-2 rounded-md text-sm font-medium text-white bg-gray-900 hover:bg-gray-700 focus:outline-none" onClick={() => handleViewToggle('ReactMainComponent', close)}>
                                        React Home
                                    </button>
                                    <button className="block w-full text-left px-3 py-2 rounded-md text-sm font-medium text-white bg-gray-900 hover:bg-gray-700 focus:outline-none" onClick={() => handleViewToggle('table', close)}>
                                        Generate Table
                                    </button>
                                    <button className="block w-full text-left px-3 py-2 rounded-md text-sm font-medium text-white bg-gray-900 hover:bg-gray-700 focus:outline-none" onClick={() => handleViewToggle('navbar', close)}>
                                        Generate Navbar
                                    </button>
                                    <button className="block w-full text-left px-3 py-2 rounded-md text-sm font-medium text-white bg-gray-900 hover:bg-gray-700 focus:outline-none" onClick={() => handleViewToggle('form', close)}>
                                        Generate Form
                                    </button>
                                    <button className="block w-full text-left px-3 py-2 rounded-md text-sm font-medium text-white bg-gray-900 hover:bg-gray-700 focus:outline-none" onClick={() => handleViewToggle('login', close)}>
                                        Generate Login
                                    </button>
                                </div>
                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>

                {activeComponent === 'ReactMainComponent' && (
                    <div className="bg-gray-900 py-10 w-full">
                        <div className="px-4 sm:px-6 lg:px-8 w-full">
                            <div className="sm:flex sm:items-center">
                                <div className="sm:flex-auto">
                                <div className="bg-gray-800 p-6 rounded-lg shadow-lg text-white">
  <h2 className="text-2xl font-bold mb-4">Guide to React Component Generation</h2>
  <p className="mb-6">
    The React component generator creates various reusable components with specific functionalities tailored to your needs. These components include tables, navbars, forms, and login pages, each designed to facilitate specific aspects of a user interface while following consistent styling.
  </p>

  <h3 className="text-xl font-semibold mb-3">Overview of Generated Components</h3>

  <h4 className="text-lg font-semibold mb-2">Table Component</h4>
  <p className="mb-4">
    Generates a React table to display data. The component is flexible, allowing you to pass headers and dynamically generated content.
  </p>

  <h4 className="text-lg font-semibold mb-2">Navbar Component</h4>
  <p className="mb-4">
    Creates a navigation bar with links tailored to the needs of your application.
  </p>

  <h4 className="text-lg font-semibold mb-2">Form Component</h4>
  <p className="mb-4">
    Generates a form with fields based on your data requirements, such as user information, contact forms, etc.
  </p>

  <h4 className="text-lg font-semibold mb-2">Login Component</h4>
  <p className="mb-4">
    Provides a login form for user authentication. Includes inputs for username/email and password, with validation and error handling.
  </p>

  <h3 className="text-xl font-semibold mb-3">Summary</h3>
  <p className="mb-6">
    These generated components allow you to quickly create a functional user interface for your application with a consistent look and feel. You can further customize each component to match your application’s needs by adjusting headers, form fields, or API endpoints. Tailwind CSS provides a responsive, modern look to ensure a smooth user experience.
  </p>
</div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {activeComponent === 'navbar' && <NavbarForm />}
                {activeComponent === 'table' && <TableForm />}
                {activeComponent === 'form' && <FormForm />}
                {activeComponent === 'login' && <LoginForm />}
            </div>
        </div>
    );
}

export default ReactMainComponent;