import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import axiosInstance from '../axios';
import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded';

const StyledContainer = styled(Container)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(3),
  boxShadow: theme.shadows[5],
}));

const StyledBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(8),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  margin: theme.spacing(1),
  backgroundColor: theme.palette.secondary.main,
}));

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="">
        SnapCode
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const SignUp = ({ handleLoginSelection = () => {} }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
  });
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false); // New state to handle success state

  const handleLinkClick = (selection) => {
    handleLoginSelection(selection);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');
  
    if (!formData.name || !formData.email || !formData.password) {
      setError('All fields are required.');
      return;
    }
  
    try {
      await axiosInstance.post('registerOnline', formData);
      setSuccessMessage('Form submitted successfully! Welcome!');
      setIsSuccess(true); // Set success state
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error && error.response.data.error.message) {
        setError(error.response.data.error.message);
      } else {
        setError('An error occurred while submitting the form. Please try again later.');
      }
    }
  };

  useEffect(() => {
    if (isSuccess) {
      const timer = setTimeout(() => {
        handleLinkClick('user');
      }, 2000); // Redirect after 2 seconds
      return () => clearTimeout(timer); // Cleanup the timer
    }
  }, [isSuccess]);

  return (
    <div className='tausssta'>
      <div className='adminlogin'>
        <StyledContainer component="main" maxWidth="xs">
          <CssBaseline />
          <StyledBox>
            <StyledAvatar>
              <EditNoteRoundedIcon />
            </StyledAvatar>
            <Typography component="h1" variant="h5">
              Sign up
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="name"
                label="Name"
                name="name"
                autoComplete="name"
                autoFocus
                value={formData.name}
                onChange={handleChange}
                sx={{
                  backgroundColor: 'white',
                  borderRadius: 1,
                }}
                error={!!error}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={formData.email}
                onChange={handleChange}
                sx={{
                  backgroundColor: 'white',
                  borderRadius: 1,
                }}
                error={!!error}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={formData.password}
                onChange={handleChange}
                sx={{
                  backgroundColor: 'white',
                  borderRadius: 1,
                }}
                error={!!error}
              />
  
              {error && <Typography color="error" sx={{ mt: 2 }}>{error}</Typography>}
              {successMessage && <Typography color="primary" sx={{ mt: 2 }}>{successMessage}</Typography>}
  
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign Up
              </Button>
              <Grid container>
                <Grid item>
                  <Link href="#" variant="body2">
                    <a onClick={() => handleLinkClick('user')}>Already have an account? Sign in</a>
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </StyledBox>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </StyledContainer>
      </div>
    </div>
  );
}

export default SignUp;
