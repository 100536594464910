import React, { useEffect } from 'react';

// Define available cast types based on field types
const castOptionsByFieldType = {
    string: ['string', 'integer', 'float', 'boolean', 'array', 'datetime', 'json'],
    integer: ['integer', 'float', 'boolean', 'string'],
    float: ['float', 'integer', 'boolean', 'string'],
    boolean: ['boolean'],
    datetime: ['datetime', 'string'],
    timestamp: ['datetime', 'string'],
    enum: ['string', 'integer'],
    text: ['string'],
    json: ['array', 'json'],
    foreignId: ['integer'],
    // Add more field types and their corresponding cast options as needed
};

const FieldBuilder = ({ field, updateField, removeField, tables }) => {
    // Generic handler for updating field properties
    const handleChange = (property, value) => {
        const newField = { ...field, [property]: value };
        
        // If 'cast' is enabled and 'castType' is empty, set it to the first available option
        if (property === 'cast' && value === true && (!newField.castType || newField.castType === '')) {
            const availableCastTypes = castOptionsByFieldType[newField.type] || [];
            newField.castType = availableCastTypes.length > 0 ? availableCastTypes[0] : '';
        }

        // If field type changes, ensure 'castType' is valid
        if (property === 'type') {
            const availableCastTypes = castOptionsByFieldType[value] || [];
            if (newField.cast && !availableCastTypes.includes(newField.castType)) {
                newField.castType = availableCastTypes.length > 0 ? availableCastTypes[0] : '';
            }
        }

        updateField(newField);
    };

    // Handlers for specific input types
    const handleInputChange = (e) => handleChange(e.target.name, e.target.value);
    const handleCheckboxChange = (e) => handleChange(e.target.name, e.target.checked);
    const handleSelectChange = (e) => handleChange(e.target.name, e.target.value);

    // Enum options handlers
    const addEnumOption = () => {
        const newOptions = [...(field.options || []), ''];
        handleChange('options', newOptions);
    };

    const updateEnumOption = (index, value) => {
        const newOptions = [...field.options];
        newOptions[index] = value;
        handleChange('options', newOptions);
    };

    const removeEnumOption = (index) => {
        const newOptions = field.options.filter((_, i) => i !== index);
        handleChange('options', newOptions);
    };

    // Predefined field types with possible defaults
    const fieldTypes = [
        { value: 'integer', label: 'Integer' },
        { value: 'string', label: 'String' },
        { value: 'enum', label: 'Enum' },
        { value: 'datetime', label: 'Datetime' },
        { value: 'timestamps', label: 'Timestamps' },
        { value: 'email', label: 'Email' },
        { value: 'boolean', label: 'Boolean' },
        { value: 'text', label: 'Text' },
        { value: 'float', label: 'Float' },
        { value: 'json', label: 'Json' },
        { value: 'foreignId', label: 'Foreign ID' },

        // Add more types as needed
    ];

    // Determine available cast types based on current field type
    const availableCastTypes = field.type ? castOptionsByFieldType[field.type] || [] : [];

    // Effect to ensure 'castType' is set when 'cast' is enabled on mount or when field.type changes
    useEffect(() => {
        if (field.cast && (!field.castType || !availableCastTypes.includes(field.castType))) {
            const newCastType = availableCastTypes.length > 0 ? availableCastTypes[0] : '';
            updateField({ ...field, castType: newCastType });
        }
    }, [field.cast, field.type]);

    return (
        <div className='field-container p-4 mb-4 border rounded-md bg-gray-800'>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                {/* Field Name */}
                {field.type !== 'foreignId' && (
                    <div>
                        <label className='block text-sm font-medium text-gray-300'>Field Name</label>
                        <input
                            name="name"
                            className='w-full p-2 mt-1 rounded-md bg-gray-700 text-black border border-gray-600'
                            type="text"
                            value={field.name}
                            onChange={handleInputChange}
                            placeholder="Enter field name"
                            required
                        />
                    </div>
                )}

                {/* Field Type */}
                <div>
                    <label className='block text-sm font-medium text-gray-300'>Field Type</label>
                    <select
                        name="type"
                        className='w-full p-2 mt-1 rounded-md bg-gray-700 text-white border border-gray-600'
                        value={field.type}
                        onChange={handleSelectChange}
                        required
                    >
                        <option value="" disabled>Select Field Type</option>
                        {fieldTypes.map((type) => (
                            <option key={type.value} value={type.value}>{type.label}</option>
                        ))}
                    </select>
                </div>

                {/* Foreign Key References */}
                {field.type === 'foreignId' && (
                    <>
                        <div>
                            <label className='block text-sm font-medium text-gray-300'>Reference Table</label>
                            <select
                                name="references"
                                className='w-full p-2 mt-1 rounded-md bg-gray-700 text-white border border-gray-600'
                                value={field.references}
                                onChange={handleSelectChange}
                                required
                            >
                                <option value="">Select Reference Table</option>
                                {tables.map((table, index) => (
                                    <option key={index} value={table.name}>{table.name}</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label className='block text-sm font-medium text-gray-300'>On Delete</label>
                            <select
                                name="onDelete"
                                className='w-full p-2 mt-1 rounded-md bg-gray-700 text-black border border-gray-600'
                                value={field.onDelete}
                                onChange={handleSelectChange}
                                required
                            >
                                <option value="cascade">Cascade</option>
                                <option value="restrict">Restrict</option>
                                <option value="set null">Set Null</option>
                                <option value="no action">No Action</option>
                            </select>
                        </div>
                    </>
                )}

                {/* Enum Options */}
                {field.type === 'enum' && (
                    <div className='col-span-2'>
                        <label className='block text-sm font-medium text-gray-300'>Enum Options</label>
                        <div className='mt-1'>
                            {field.options && field.options.map((option, index) => (
                                <div key={index} className='flex items-center mb-2'>
                                    <input
                                        className='flex-1 p-2 rounded-md bg-gray-700 text-black border border-gray-600'
                                        type="text"
                                        value={option}
                                        onChange={(e) => updateEnumOption(index, e.target.value)}
                                        placeholder={`Option ${index + 1}`}
                                        required
                                    />
                                    <button
                                        type="button"
                                        className='ml-2 text-red-500 hover:text-red-700'
                                        onClick={() => removeEnumOption(index)}
                                        aria-label={`Remove Option ${index + 1}`}
                                    >
                                        &times;
                                    </button>
                                </div>
                            ))}
                            <button
                                type="button"
                                className='mt-2 bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600'
                                onClick={addEnumOption}
                            >
                                Add Enum Option
                            </button>
                        </div>
                    </div>
                )}

                {/* Default Value */}
                {field.type && (
                    <div>
                        <label className='block text-sm font-medium text-gray-300'>Default Value</label>
                        <input
                            name="default"
                            className='w-full p-2 mt-1 rounded-md bg-gray-700 text-black border border-gray-600'
                            type="text"
                            value={field.default || ''}
                            onChange={handleInputChange}
                            placeholder="Enter default value"
                        />
                    </div>
                )}
            </div>

            {/* Additional Options */}
            {field.type && (
                <div className='mt-4 grid grid-cols-1 md:grid-cols-2 gap-4'>
                    {/* Nullable */}
                    <div className='flex items-center'>
                        <input
                            id="nullable"
                            name="nullable"
                            type="checkbox"
                            checked={field.nullable || false}
                            onChange={handleCheckboxChange}
                            className='h-4 w-4 text-blue-600 border-gray-300 rounded'
                        />
                        <label htmlFor="nullable" className='ml-2 block text-sm text-gray-300'>
                            Nullable
                        </label>
                    </div>

                    {/* Unique */}
                    <div className='flex items-center'>
                        <input
                            id="unique"
                            name="unique"
                            type="checkbox"
                            checked={field.unique || false}
                            onChange={handleCheckboxChange}
                            className='h-4 w-4 text-blue-600 border-gray-300 rounded'
                        />
                        <label htmlFor="unique" className='ml-2 block text-sm text-gray-300'>
                            Unique
                        </label>
                    </div>

                    {/* Fillable */}
                    <div className='flex items-center'>
                        <input
                            id="fillable"
                            name="fillable"
                            type="checkbox"
                            checked={field.fillable !== false} // Default to true
                            onChange={handleCheckboxChange}
                            className='h-4 w-4 text-blue-600 border-gray-300 rounded'
                        />
                        <label htmlFor="fillable" className='ml-2 block text-sm text-gray-300'>
                            Fillable
                        </label>
                    </div>

                    {/* Cast */}
                    <div className='flex items-center'>
                        <input
                            id="cast"
                            name="cast"
                            type="checkbox"
                            checked={field.cast || false}
                            onChange={handleCheckboxChange}
                            className='h-4 w-4 text-blue-600 border-gray-300 rounded'
                        />
                        <label htmlFor="cast" className='ml-2 block text-sm text-gray-300'>
                            Cast
                        </label>
                    </div>

                    {/* Cast Type */}
                    {field.cast && (
                        <div className='col-span-2'>
                            <label className='block text-sm font-medium text-gray-300'>Cast Type</label>
                            <select
                                name="castType"
                                className='w-full p-2 mt-1 rounded-md bg-gray-700 text-white border border-gray-600'
                                value={field.castType || (field.type === 'foreignId' ? 'integer' : (availableCastTypes.length > 0 ? availableCastTypes[0] : ''))}
                                onChange={handleInputChange}
                                required
                            >
                                <option value="" disabled>Select Cast Type</option>
                                {availableCastTypes.map((castType) => (
                                    <option key={castType} value={castType}>{castType}</option>
                                ))}
                            </select>
                        </div>
                    )}
                </div>
            )}

            {/* Remove Field Button */}
            <div className='mt-4 flex justify-end'>
                <button
                    type="button"
                    className='bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600'
                    onClick={removeField}
                >
                    Remove Field
                </button>
            </div>
        </div>
    );
};

export default FieldBuilder;
