import React, { useState } from 'react';
import axiosInstance from '../../axios';

const LoginForm = () => {
  const [fields, setFields] = useState(['email', 'password']);
  const [generatedCode, setGeneratedCode] = useState('');
  const [notification, setNotification] = useState({ message: '', type: '' });

  const handleChange = (index, event) => {
    const newFields = [...fields];
    newFields[index] = event.target.value;
    setFields(newFields);
  };

  const handleAddField = () => {
    setFields([...fields, '']);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axiosInstance.post('/user/reacTlogin', { fields });
      setGeneratedCode(response.data.code);
      setFields(['email', 'password']);
      setNotification({ message: 'Login form generated successfully!', type: 'success' });
    } catch (error) {
      setNotification({ message: 'Error generating login form. Please try again.', type: 'error' });
      console.error('Error generating form:', error);
    }

    setTimeout(() => {
      setNotification({ message: '', type: '' });
    }, 3000);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(generatedCode).then(() => {
      setNotification({ message: 'Code copied to clipboard!', type: 'success' });
    }).catch((err) => {
      setNotification({ message: 'Failed to copy text. Please try again.', type: 'error' });
      console.error('Failed to copy text:', err);
    });

    setTimeout(() => {
      setNotification({ message: '', type: '' });
    }, 3000);
  };

  return (
    <div className="kotipituus bg-gray-900 w-full">
      {notification.message && (
        <div
          className={`mb-4 p-4 rounded-md ${
            notification.type === 'success' ? 'bg-green-500' : 'bg-red-500'
          } text-white`}
        >
          {notification.message}
        </div>
      )}
      <form onSubmit={handleSubmit}>
        {fields.map((field, index) => (
          <div key={index}>
            <label>
              {index + 1}. Field:
              <input className="text-black" type="text" value={field} onChange={(e) => handleChange(index, e)} />
            </label>
          </div>
        ))}
        <button type="button" onClick={handleAddField}>Add Another Form Field</button>
        <button type="submit">Generate Login</button>
      </form>
      {generatedCode && (
        <div className='kotipituus bg-gray-900 w-full'>
          <div className='reactEditorContent'>
            <h3>Generated Form File</h3>
            <textarea readOnly value={generatedCode} rows="15" cols="80" />
            <button onClick={handleCopy}>Copy</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default LoginForm;
