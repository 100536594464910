import React from 'react';
import './LaravelBlog.css';

const ExpressBlogPost =({ handleLoginSelection }) => {
  const handleLinkClick = (selection) => {
    handleLoginSelection(selection);
    const navbarLinks = document.querySelector('.linkki');
    navbarLinks.classList.remove('active');
  };
  return (
    <div className="laravel-blog-container">
      <h1 className="laravel-main-headline">
        Supercharge Your Express.js Development with SnappCode: Build Backends Faster than Ever
      </h1>

      <div className="laravel-section">
        <div className="laravel-image-express"></div>
        <div className="laravel-section-content">
        <p className="laravel-section-paragraph">
            In today's dynamic world of web development, speed and productivity are crucial for success. Express.js, a fast and minimalist Node.js framework, has gained widespread popularity among developers for creating powerful backend services with ease. Enhancing your Express.js projects with SnappCode can significantly streamline the development process by automatically generating core components such as routes, models, and controllers. This integration not only boosts Express.js's capabilities but also highlights the benefits of using Express.js, unlocking limitless opportunities for modern web applications.
          </p>
          <ul className="laravel-section-list">
            <li className="laravel-section-list-item">
              <p className="laravel-section-paragraph">
                Express.js provides a lightweight and unopinionated approach to building web applications, giving developers the flexibility to choose the tools they need. Its simplicity allows for rapid prototyping, making it an excellent choice for projects where speed and customization are key.
              </p>
            </li>
            <li className="laravel-section-list-item">
              <p className="laravel-section-paragraph">
                Additionally, Express.js benefits from a large and active community, with numerous libraries and plugins available to extend its capabilities. This flexibility, combined with the powerful features of Node.js, ensures that developers can build scalable and high-performance applications efficiently.
              </p>
            </li>
          </ul>
          
        </div>
        
      </div>

      <div className="laravel-centered-section">
      <button
          type="submit"
          className="linkki bg-blue-600 hover:bg-blue-500 text-white font-bold py-4 px-10 mt-4 mb-8 rounded-lg shadow-lg transition duration-300"
          onClick={() => handleLinkClick('signUp')}
        >
          Sign Up!
        </button>
        <h2 className="laravel-centered-headline">Introducing SnappCode: Your Express.js Development Sidekick</h2>
        <p className="laravel-centered-paragraph">
          SnappCode is the ideal companion for developers working with Express.js, simplifying backend development by automating essential components. By reducing repetitive coding tasks, SnappCode allows you to spend more time creating unique features and improving your application's overall quality.
        </p>
        <div className="laravel-image-express2"></div>
        <h3 className="laravel-centered-headline mt-12">How SnappCode Enhances Express.js Development</h3>
        <ul className="laravel-centered-list mt-12">
          <li className="laravel-centered-list-item">
            <strong>Automatic Route Generation</strong>
            <p className="laravel-centered-paragraph">
              Setting up routes is essential for connecting your application's API endpoints with their respective handlers. SnappCode automates the process of creating organized route definitions, ensuring consistent and error-free routing throughout your project.
            </p>
          </li>
          <li className="laravel-centered-list-item">
            <strong>Streamline Controller Creation</strong>
            <p className="laravel-centered-paragraph">
              Controllers handle the logic for your application's routes. With SnappCode, you can generate controllers equipped with the necessary methods quickly and efficiently, allowing you to focus on the core logic and features of your Express.js application.
            </p>
          </li>
          <li className="laravel-centered-list-item">
            <strong>Model Generation Simplified</strong>
            <p className="laravel-centered-paragraph">
              Models represent your application's data and business logic. SnappCode simplifies the creation of models, ensuring your data layer is well-structured and easy to maintain while following best practices.
            </p>
          </li>
          <li className="laravel-centered-list-item">
            <strong>Customizable Generation Templates</strong>
            <p className="laravel-centered-paragraph">
              SnappCode allows you to customize the templates used to generate your Express.js components. Modify route handlers, validation rules, and model definitions to meet your specific project needs, ensuring your codebase aligns perfectly with your requirements.
            </p>
          </li>
        </ul>
        <button
          type="submit"
          className="linkki bg-green-600 hover:bg-green-500 text-white font-bold py-4 px-10 mt-4 rounded-lg shadow-lg transition duration-300"
          onClick={() => handleLinkClick('signUp')}
        >
          Create Account!
        </button>
      </div>
    </div>
  );
};

export default ExpressBlogPost;
