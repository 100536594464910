import React from 'react';

const FieldBuilder = ({ field, updateField, removeField, tables }) => {
    const updateFieldName = (e) => {
        const newField = { ...field, name: e.target.value };
        updateField(newField);
    };

    const updateFieldType = (e) => {
        const newField = { ...field, type: e.target.value };
        updateField(newField);
    };

    const updateFieldReference = (e) => {
        const newField = { ...field, reference: e.target.value, type: 'INTEGER' }; // Reference type will be handled as INTEGER
        updateField(newField);
    };

    const updateFieldRequired = (e) => {
        const newField = { ...field, allowNull: !e.target.checked }; // In Sequelize, required fields use allowNull: false
        updateField(newField);
    };

    const updateFieldUnique = (e) => {
        const newField = { ...field, unique: e.target.checked };
        updateField(newField);
    };

    const updateFieldDefault = (e) => {
        const newField = { ...field, defaultValue: e.target.value }; // Sequelize uses defaultValue
        updateField(newField);
    };

    const addEnumOption = () => {
        const newOptions = [...(field.options || []), ''];
        const newField = { ...field, options: newOptions };
        updateField(newField);
    };

    const updateEnumOption = (index, value) => {
        const newOptions = [...field.options];
        newOptions[index] = value;
        const newField = { ...field, options: newOptions };
        updateField(newField);
    };

    return (
        <div className='fieldcontainer max-w-full'>
            <input
                className='w-full max-w-full sm:max-w-xs p-2 rounded-md bg-gray-700 text-black border border-gray-600'
                type="text"
                value={field.name}
                onChange={updateFieldName}
                placeholder="Field Name"
            />
            <select
                className='w-full max-w-full sm:max-w-xs p-2 rounded-md text-black border border-gray-600 mt-2'
                value={field.type}
                onChange={updateFieldType}
            >
                <option value="STRING">String</option>
                <option value="INTEGER">Number</option>
                <option value="BOOLEAN">Boolean</option>
                <option value="DATE">Date</option>
                <option value="ENUM">Enum</option>
                <option value="Reference">Reference</option>
            </select>

            {field.type === 'Reference' && (
                <select
                    className='w-full max-w-md p-2 rounded-md border border-gray-300 mt-2 text-black'
                    value={field.reference}
                    onChange={updateFieldReference}
                >
                    <option value="">Select Reference Table</option>
                    {tables.map((table, index) => (
                        <option key={index} value={table.name}>{table.name}</option>
                    ))}
                </select>
            )}

            {field.type === 'ENUM' && (
                <>
                    <button
                        className='bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 mt-2'
                        onClick={addEnumOption}
                    >
                        Add Enum Option
                    </button>
                    {field.options && field.options.map((option, index) => (
                        <input
                            key={index}
                            className='w-full max-w-md p-2 rounded-md border border-gray-300 mt-2 text-black'
                            type="text"
                            value={option}
                            onChange={(e) => updateEnumOption(index, e.target.value)}
                            placeholder={`Option ${index + 1}`}
                        />
                    ))}
                </>
            )}

            {field.type && field.type !== 'Reference' && (
                <div className='flex flex-wrap items-start space-y-2 mt-2'>
                    <input
                        className='w-full max-w-md p-2 rounded-md border border-gray-300 mt-2 text-black'
                        type="text"
                        value={field.defaultValue || ''}
                        onChange={updateFieldDefault}
                        placeholder="Default Value"
                    />
                    <div className='w-full'>
                        <label className='flex items-center space-x-2 mt-2'>
                            <input type="checkbox" checked={!field.allowNull} onChange={updateFieldRequired} />
                            <span>Required</span>
                        </label>
                    </div>
                    <div className='w-full'>
                        <label className='flex items-center space-x-2 mt-2'>
                            <input type="checkbox" checked={field.unique || false} onChange={updateFieldUnique} />
                            <span>Unique</span>
                        </label>
                    </div>
                </div>
            )}

            <button
                className='bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 mt-4'
                onClick={removeField}
            >
                Remove Field
            </button>
        </div>
    );
};

export default FieldBuilder;
